import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styled from 'styled-components';
import { withTheme } from './../../themes';
import Icon from "./../App/Icon"
import { getLayout } from './../../store/settings/selectors'
import {LAYOUT_XS, LAYOUT_SM} from './../../settings';
import {setStatus, showFullModal} from "./../../store/settings/actions";

const Wrapper  = styled.div`
`;

const VisibleContent = styled.div`
  /* border: 1px solid lime; */
`;

const ShowMore = styled.div`
  text-align: center;
  padding-top: 15px;
  /* border: 1px solid orange; */
`;

const ReadMoreModal = ({
  visibleContent,
  hiddenContent,
  buttonMore
}) => {
  const layout = useSelector(getLayout);
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <VisibleContent>
        {visibleContent}
        {hiddenContent ?
          <ShowMore onClick={() => dispatch(showFullModal(hiddenContent))}>{buttonMore}</ShowMore>
        : null }
      </VisibleContent>
    </Wrapper>
  )
}


export default withTheme(ReadMoreModal)
