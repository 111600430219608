export const VIR = {
  tenant: "VIR",
  name: "VinitalyPlus",
  default_language: 'it',
  languages: ["it", "en", "fr", "zh", "ru", "ko", "ja", "pt", "es", "de"],
  enable_login: true,
  enable_helpdesk: true,
  enable_favorites: true,
  enable_suggested: true,
  enable_halls: true,
  enable_chat: false,
  enable_bcards: true,
  enable_memos: true,
  enable_wizard: true,
  enable_agenda: false,
  enable_terms: true,
  enable_languages_in_menu: true,
  tracking: [{
    type: 'hotjar',
    code: 183316,
    domains: ['vinitalyplus.com']
  }, {
    type: 'analytics',
    code: 'UA-1768890-1', //fiera produzione
    domains: ['vinitalyplus.com'],
  }, {
    type: 'analytics',
    code: 'UA-169833767-1', //interno test
    domains: ['recat-vir-test.firebaseapp.com'],
  }, {
    type: 'analytics',
    code: 'UA-169833767-2', //interno produzione
    domains: ['vinitalyplus.com'],
  }],
  share_networks: ["facebook", "twitter", "pinterest"],
  translations: {
    url: "https://ozwol-tech-cdn.s3-eu-west-1.amazonaws.com/veronafiere/vinitaly/",
  },
  ozwol: {
    endpoint: "https://ff9gdqk165.execute-api.eu-west-1.amazonaws.com/api/", //https://api.ozwol.cloud/v1/executeScript
    apiKey: "f9dc3e58-acc9-466f-9af1-085a19f86015",
    projectId: "47S3WM8K6G",
    cdn: "https://cdn.ozwol.cloud/47S3WM8K6G"
  },
  sendmail: {
    endpoint: "https://us-central1-recat-vir.cloudfunctions.net/app/api/sendmail",
    email: "noreply@veronafiere.it",
    bcc: "sara.potyscki@ozwol.com" //separati da virgola
  },
  cookiebot: "60f72fd7-cdea-4d9c-9981-952edf8748b8",
  auth0: {
    domain: "login.veronafiere.it",
    clientID: "4UNpGmm7lSwZdTRORB1OZ06fbpMOl3nZ",
    responseType: "token id_token",
    scope: 'openid profile email',
    audience: "https://veronafiere.api.it",
    issuer: 'https://login.veronafiere.it/',
  },
  bcards: {
    apiUrl: "https://vrf-vinitaly-app.appspot.com/user/bcards/"
  },
  memos: {
    apiUrl: "https://vrf-vinitaly-app.appspot.com/user/memos/"
  },
  news: {
    apiUrl: "https://apicatalogo.veronafiere.it/v1/VIR/contenuti_plus/"
  },
  recommended: {
    apiUrl: "https://apicatalogo.veronafiere.it/v1/VIR/users/"
  },
  areas:{
    "companies": {
      icon: 'company.svg',
      dataServiceKey: "exhibitors",
      enable: true,
      default: true,
      routes: {
        list: 'companies',
        detail: 'companies/{slug}'
      },
      subareas: {
        "companies": {
          default: true,
          key: "company",
          dataServiceKey: "",
          enable: true,
          enable_detail: true
        }
      },
      actions: {
        meeting: {
          enable: false
        }
      }
    },
    "products": {
      icon: 'product.svg',
      dataServiceKey: "products",
      enable: true,
      default: false,
      routes: {
        list: 'products/{type}',
        detail: 'products/{type}/{slug}'
      },
      subareas: {
        "wines": {
          default: true,
          key: "wine",
          dataServiceKey: "wine",
          enable: true,
          enable_detail: true
        },
        "spirits": {
          default: false,
          key: "spirit",
          dataServiceKey: "grappa",
          enable: true,
          enable_detail: true
        }
      }
    },
    "events": {
      icon: 'event.svg',
      dataServiceKey: "events",
      enable: true,
      default: false,
      routes: {
        list: 'events/{type}',
        detail: 'events/{type}/{slug}'
      },
      subareas: {
        "virtual": {
          default: false,
          key: "virtual",
          dataServiceKey: "virtual",
          enable: true,
          enable_detail: true
        },
        "indoor": {
          default: true,
          key: "indoor",
          dataServiceKey: "indoor",
          enable: true,
          enable_detail: true
        },
        "outdoor": {
          default: false,
          key: "outdoor",
          dataServiceKey: "outdoor",
          enable: true,
          enable_detail: false
        }
      }
    },
    "casehistories": {
      default: false,
      enable: false,
      subareas: null
    },
  },
  accountSettings: {
    theme: "vinitaly",
    proAccount: true,
  },
  chatConfig: {
    default: {
      pluginEnv: 'staging',
      wsEndpoint: 'wss://qh2k5m2qw1.execute-api.eu-west-1.amazonaws.com/test/',
      apiUrl: 'https://11o2teju4f.execute-api.eu-west-1.amazonaws.com/staging/vir/',
      favoritesUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/recents',
      bcardsUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/bcard'
    },
    ['recat-vir-test.firebaseapp.com']: {
      pluginEnv: 'staging',
      wsEndpoint: 'wss://qh2k5m2qw1.execute-api.eu-west-1.amazonaws.com/test/',
      apiUrl: 'https://11o2teju4f.execute-api.eu-west-1.amazonaws.com/staging/vir/',
      favoritesUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/recents',
      bcardsUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/bcard'
    },
    ['recat-vir-staging.firebaseapp.com']: {
      pluginEnv: 'staging',
      wsEndpoint: 'wss://chat.quickchat.online/vrf/',
      apiUrl: 'https://l1iq9e6hm6.execute-api.eu-west-1.amazonaws.com/production/vir/',
      favoritesUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/recents',
      bcardsUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/bcard'
    },
    ['catalogo.vinitaly.com']: {
      pluginEnv: 'production',
      wsEndpoint: 'wss://chat.quickchat.online/vrf/',
      apiUrl: 'https://l1iq9e6hm6.execute-api.eu-west-1.amazonaws.com/production/vir/',
      favoritesUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/recents',
      bcardsUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/bcard'
    },
    ['vinitalyplus.com']: {
      pluginEnv: 'production',
      wsEndpoint: 'wss://chat.quickchat.online/vrf/',
      apiUrl: 'https://l1iq9e6hm6.execute-api.eu-west-1.amazonaws.com/production/vir/',
      favoritesUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/recents',
      bcardsUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/bcard'
    }
  },
  awsAnalytics: {
    localhost: null,
    default: {
      area: "staging",
      credentials: {
        accessKeyId: "AKIATLSVYBWBKK4LNFSX",
        secretAccessKey: "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"
      },
      region: "eu-west-1",
      deliveryStreamName: "testCatalogoWebFirehoseDelivery"
    },
    ['vinitalyplus.com']: {
      area: "production",
      credentials: {
        accessKeyId: "AKIAUCEJSNJLSFSBXY7V",
        secretAccessKey: "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"
      },
      region: "eu-west-1",
      deliveryStreamName: "VIRCatalogoWebFirehoseDeliveryStream"
    }
  },
  "needPermissionTo": ["filters"],
  "letzFair": {
    enabled: true,
    jsFile: "https://vinitaly-visitatori.letzfair.com/ext.js"
  }
};
