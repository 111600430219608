import React, {useState} from "react";
import { Link, withRouter } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "./../../themes";
import styled from "styled-components";

import {getConfig, getLayout, getStatus} from "./../../store/settings/selectors";
import {getRoles, getUser, getFavorites } from './../../store/auth/selectors';
import {getLocale, getTranslations} from "./../../store/localization/selectors";
import {setStatus} from "../../store/settings/actions";
import {setResultsType} from "../../store/settings/actions";
import {login, logout} from "../../store/auth/actions";

import {LAYOUT_SM, LAYOUT_XS} from "./../../settings";

import LanguageSelector from "../Common/LanguageSelector";
import Icon from "./../App/Icon";
import Badge from "./../App/Badge";
import MenuItem from "./../App/MenuItem";
import UserTag from "./UserTag";
import Topbar from "../../components/Layout/Topbar/Topbar";

import { version } from "./../../../package.json";
import { DOMAINS } from "./../../domains"
import {getTranslation} from "../../helpers";

const Wrapper = styled.div`
  position: fixed;
  top: 0px;

  ${ props => props.compact ? "left" : "right" }: ${ props => props.isOpen ? "0px" : "-100%" };
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "100%" : (props.isOpen ? "100%" : "380px") };
  height: 100%;
  transition: all 0.25s ease-in-out;
  z-index: 99999;
`;
const ShadowArea = styled.div`
  position: absolute;
  top: 0px;
  ${ props => props.compact ? "right" : "left" }: 0px;
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "80px" : "calc(100% - 380px)" };

  height: 100%;
`;
const PanelArea = styled.div`
  position: absolute;
  top: 0px;
  ${ props => props.compact ? "left" : "right" }: 0px;
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "calc(100% - 80px)" : "380px" };
  height: calc(100% - ${ props => props.compact ? 0 : 15 }px);
  background: ${ props => props.background };
  box-shadow: -3px 0px 5px #0000000F;
`;
const Version = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "calc(100% - 80px)" : "380px" };
  height: 15px;
  background: ${ props => props.background };
  color: ${ props => props.color };
  font-size: 10px;
  font-family: Consolas;
  line-height: 16px;
  padding: 0px 5px;
`;

const FlatButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  width: 100%;
`;

function handleSetResultsType(history, dispatch, link){
  dispatch(setResultsType("favorites"));
  history.push(link);
}

function UserPanel({history}) {
  const dispatch = useDispatch();

  const favAreas = {
    "companies": "exhibitor",
    "products": "product",
    "events": "event",
    "casehistories": "project"
  };

  const theme = useTheme();
  const translations = useSelector(getTranslations);
  const layout = useSelector(getLayout);
  const status = useSelector(getStatus);
  const lang = useSelector(getLocale);
  const user = useSelector(getUser);
  const favorites = useSelector(getFavorites);
  const roles = useSelector(getRoles);
  const config = useSelector(getConfig);

// console.log(config.areas.events.subareas);
  let higherRole;
  if(roles){
    higherRole = roles.indexOf('userPremium') !== -1 ? translations["UI"]["_plan-lv2-badge"] : roles.indexOf('userBasic') !== -1 ? translations["UI"]["_plan-lv1-badge"] : null;
  }

  const SECTION_LANGUAGE = 1;
  const SECTION_FAVORITES = 2;
  const [section, setSection] = useState(status.userpanelSection);
  if(status.userpanelSection && section !== status.userpanelSection){
    setSection(status.userpanelSection);
    dispatch(setStatus({
      userpanelSection: null
    }), [dispatch])
  }
  // console.log(status.userpanelSection);
  // console.log(section);
  // console.log(process.env);

  function agendaHandler() {

    if (!(user && user?.id)) return null;

    const args = {
      "nome": user.firstname,
      "cognome": user.lastname,
      "email": user.email,
      "idUtente": user.id,
    }

    if (window.letzfair) {
      window.letzfair('agenda', args);
      // console.log('[LETZFAIR] agenda');
    } else {
      // console.log('[LETZFAIR] window.letzfair not found')
    }
    // console.log('[LETZFAIR] args:', args)

  }

  return (
    <Wrapper layout={layout} isOpen={status.isUserpanelOpen} compact={(config.enable_compact_mobile_menu && (layout === LAYOUT_XS || layout === LAYOUT_SM))}>
      <ShadowArea compact={(config.enable_compact_mobile_menu && (layout === LAYOUT_XS || layout === LAYOUT_SM))} layout={layout} onClick={() => dispatch(setStatus({
        isUserpanelOpen: false,
        userpanelSection: false
      }), [dispatch])} />
      <PanelArea compact={(config.enable_compact_mobile_menu && (layout === LAYOUT_XS || layout === LAYOUT_SM))} layout={layout} background={theme.userpanel.background}>
        {(config.enable_compact_mobile_menu && (layout === LAYOUT_XS || layout === LAYOUT_SM)) ? 
          <Topbar compact={true} />
        : null }
        {(() => {
          switch (section) {
            case SECTION_LANGUAGE:
              return <>
                <MenuItem
                  onClick={() => setSection(null)}
                  leftIcon={<Icon
                    name={theme.icons.set.menuitemPrev}
                    color={theme.menuitem.variant[theme.userpanel.menuitemVariantBar].normal.textColor}
                  />}
                  variant={theme.userpanel.menuitemVariantBar}
                >{translations["UI"]["Back"]}</MenuItem>
                <LanguageSelector/>
              </>;
            case SECTION_FAVORITES:
              return <>
                <MenuItem
                  onClick={() => setSection(null)}
                  leftIcon={<Icon
                    name={theme.icons.set.menuitemPrev}
                    color={theme.menuitem.variant[theme.userpanel.menuitemVariantBar].normal.textColor}
                  />}
                  variant={theme.userpanel.menuitemVariantBar}
                >{translations["UI"]["Back"]}</MenuItem>
                { Object.keys(config.areas).filter(keyArea => config.areas[keyArea].enable).map(keyArea => (
                  <MenuItem
                    key={keyArea}
                    onClick={() => handleSetResultsType(history, dispatch, "/"+lang+"/"+keyArea+( Object.keys(config.areas[keyArea].subareas).filter(keySubarea => config.areas[keyArea].subareas[keySubarea].enable).length > 1 ? "/"+Object.keys(config.areas[keyArea].subareas).filter(keySubarea => config.areas[keyArea].subareas[keySubarea].default)[0] : ""))}
                    rightIcon={favorites ? <Badge>{favorites.filter(item => item.itemType === favAreas[keyArea]).length}</Badge> : null }
                    color={theme.primaryColor}
                  >{translations["UI"]["_label-"+keyArea]}</MenuItem>
                ))}
              </>;
            default:
              return <>
                {!(config.enable_compact_mobile_menu && (layout === LAYOUT_XS || layout === LAYOUT_SM)) ?
                  <MenuItem
                    onClick={() => {}}
                    leftIcon={<UserTag />}
                    variant={theme.userpanel.menuitemVariantBar}
                  >{user ? user.firstname + " " + user.lastname : translations["UI"]["Guest user"]}</MenuItem>
                : null }
                {user ?
                  <>
                    <Link to={"/"+lang+"/myarea"}>
                      <MenuItem
                        onClick={() => {}}
                        leftIcon={<Icon
                          name={theme.icons.set.profile}
                          color={theme.primaryColor}
                        />}
                        rightIcon={higherRole ? <Badge variant={"primary"}>{higherRole}</Badge> : null}
                        variant={theme.userpanel.menuitemVariantItem}
                      >{translations["UI"]["MyArea"]}</MenuItem>
                    </Link>
                    {DOMAINS[window.location.hostname].enable_bcards ?
                      <Link to={"/"+lang+"/mybcard"}>
                        <MenuItem
                          onClick={() => {}}
                          leftIcon={<Icon
                            name={theme.icons.set.profile}
                            color={theme.primaryColor}
                          />}
                          rightIcon={higherRole ? <Badge variant={"primary"}>{higherRole}</Badge> : null}
                          variant={theme.userpanel.menuitemVariantItem}
                        >{getTranslation(translations, ['UI', 'MyBusinessCard'])}</MenuItem>
                      </Link>
                    : null }
                  </>
                :
                  <MenuItem
                    onClick={() => dispatch(login(), [dispatch])}
                    leftIcon={<Icon
                      name={theme.icons.set.login}
                      color={theme.primaryColor}
                    />}
                    variant={theme.userpanel.menuitemVariantItem}
                  >{translations["UI"]["Login"]}</MenuItem>
                }
                {DOMAINS[window.location.hostname].enable_languages_in_menu ?
                  <MenuItem
                    onClick={() => setSection(SECTION_LANGUAGE)}
                    leftIcon={<Icon
                      name={theme.icons.set.language}
                      color={theme.primaryColor}
                    />}
                    rightIcon={<Icon
                      name={theme.icons.set.menuitemNext}
                      color={theme.primaryColor}
                    />}
                    variant={theme.userpanel.menuitemVariantItem}
                  >{translations["UI"]["Language"]}</MenuItem>
                : null}
                <MenuItem
                  isDisabled={!user}
                  onClick={user ? () => setSection(SECTION_FAVORITES) : () => {}}
                  leftIcon={<Icon
                    name={theme.icons.set.favorite}
                    color={theme.primaryColor}
                  />}
                  rightIcon={<Icon
                    name={theme.icons.set.menuitemNext}
                    color={theme.primaryColor}
                  />}
                  variant={theme.userpanel.menuitemVariantItem}
                >{translations["UI"]["Favorites"]}</MenuItem>
                {DOMAINS[window.location.hostname].enable_memos ?
                  <Link to={user ? "/"+lang+"/memo" : "#"}>
                    <MenuItem
                      isDisabled={!user}
                      onClick={() => {}}
                      leftIcon={<Icon
                        name={theme.icons.set.memo}
                        color={theme.primaryColor}
                      />}
                      variant={theme.userpanel.menuitemVariantItem}
                    >{translations["UI"]["Memo"]}</MenuItem>
                  </Link>
                : null }
                {DOMAINS[window.location.hostname].enable_bcards ?
                  <Link to={user ? "/"+lang+"/bcards" : "#"}>
                    <MenuItem
                      isDisabled={!user}
                      onClick={() => {}}
                      leftIcon={<Icon
                        name={theme.icons.set.contact}
                        color={theme.primaryColor}
                      />}
                      variant={theme.userpanel.menuitemVariantItem}
                    >{translations["UI"]["BCard"]}</MenuItem>
                  </Link>
                : null }

                {user && DOMAINS[window.location.hostname].enable_agenda ? (
                  <FlatButton onClick={agendaHandler}>
                    <MenuItem
                      leftIcon={<Icon
                        name={theme.icons.set.calendar}
                        color={theme.primaryColor}
                      />}
                      variant={theme.userpanel.menuitemVariantItem}
                    >{translations["UI"]["Agenda"]}</MenuItem>
                  </FlatButton>
                ) : null}
                {config.enable_helpdesk ?
                  <Link to={"/"+lang+"/helpdesk"}>
                    <MenuItem
                      leftIcon={<Icon
                        name={theme.icons.set.helpdesk}
                        color={theme.primaryColor}
                      />}
                      variant={theme.userpanel.menuitemVariantItem}
                    >{translations["UI"]["Help Desk"]}</MenuItem>
                  </Link>
                : null }
                {DOMAINS[window.location.hostname].enable_terms ?
                  <Link to={`/${lang}/terms`}>
                    <MenuItem
                      leftIcon={<Icon
                        name={theme.icons.set.legal}
                        color={theme.primaryColor}
                      />}
                      variant={theme.userpanel.menuitemVariantItem}
                    >{translations["UI"]["website_terms_conditions"]}</MenuItem>
                  </Link>
                : null }
                {user ?
                  <>
                    <MenuItem
                      onClick={() => dispatch(logout(), [dispatch])}
                      leftIcon={<Icon
                        name={theme.icons.set.logout}
                        color={theme.primaryColor}
                      />}
                      variant={theme.userpanel.menuitemVariantItem}
                    >{translations["UI"]["Logout"]}</MenuItem>
                  </>
                :
                  null
                }


                {/*
                <MenuItem
                  onClick={() => {}}
                  leftIcon={<Icon
                    name={theme.icons.set.logout}
                    color={theme.primaryColor}
                  />}
                  variant={theme.userpanel.menuitemVariantItem}
                >Prova</MenuItem>
                */}
              </>;
          }
        })()}
      </PanelArea>
      {!(config.enable_compact_mobile_menu && (layout === LAYOUT_XS || layout === LAYOUT_SM)) ? 
        <Version layout={layout} background={theme.userpanel.version.background} color={theme.userpanel.version.color}>Version: {version}</Version>
      : null }
    </Wrapper>



  );
}

export default withRouter(UserPanel)
