import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '../../themes'
import styled from 'styled-components';

import Container from "../App/Container"

const Bar  = styled.div`
  border-bottom: ${props => props.borderSize + "px " + props.borderStyle + " " + props.borderColor};
  background: ${props => props.background};
  box-shadow: ${props => props.boxShadow};
  position: sticky;
  top: 0;
  z-index: 5;
`;
const Wrapper  = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.h.value + props.h.unit};
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};

`;
const Title  = styled.div`
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};
`;

const SheetTopbar = ({
  variant = "default",
  title,
  anchors,
  theme
}) => {

  return (

    <Bar
      background={theme.sheetTopbar.variant[variant].bar.background}
      borderStyle={theme.sheetTopbar.bar.borderStyle}
      borderSize={theme.sheetTopbar.bar.borderSize}
      borderColor={theme.sheetTopbar.variant[variant].bar.borderColor}
      boxShadow={theme.sheetTopbar.variant[variant].bar.boxShadow}
    >
      <Container>
        <Wrapper
          h={theme.sheetTopbar.bar.height}
          padding={theme.sheetTopbar.bar.padding}
        >
          <Title
            textFont={theme.sheetTopbar.title.textFont}
            textSize={theme.sheetTopbar.title.textSize}
            textWeight={theme.sheetTopbar.title.textWeight}
            textLineHeight={theme.sheetTopbar.title.textLineHeight}
            textLetterSpacing={theme.sheetTopbar.title.textLetterSpacing}
            textTransform={theme.sheetTopbar.title.textTransform}
            textColor={theme.sheetTopbar.variant[variant].title.textColor}
          >{title}</Title>

          {anchors ? anchors : ""}
        </Wrapper>
      </Container>
    </Bar>
  )
}

SheetTopbar.propTypes = {
  title: PropTypes.string,
  anchors: PropTypes.element
}

export default withTheme(SheetTopbar)
