import React from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getLocale, getTranslations} from "../../store/localization/selectors";

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 30px auto;
  background: #fff;
  padding: 20px;
  border: 1px solid rgb(237,237,237);
  * {
    line-height: 1.5em;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`

function Terms() {

  const locale = useSelector(getLocale);
  console.log('locale', locale);

  return (
    <Container>
      {locale === 'it' ? (
        <div>
          <h1>INFORMATIVA AI SENSI DELL’ART. 13 DEL REG. (UE) 2016/679 (“GDPR”) – “CONTATTA”</h1>
          <p>Di seguito si forniscono le informazioni circa il trattamento dei dati personali conferiti nell’apposito form sulla piattaforma online dagli utenti al fine di essere messi in contatto con le aziende presenti su tale piattaforma.</p>
          <p><strong>1. Identità e dati di contatto del titolare del trattamento</strong></p>
          <p>Titolare del trattamento è Veronafiere S.p.A., Viale del Lavoro, 8, 37135 – Verona (VF), tel. 045 8298111, indirizzo e-mail info@veronafiere.it, indirizzo PEC mail@pec.veronafiere.it (“Veronafiere” o “Titolare”). Dati di contatto del responsabile della protezione dati (DPO)</p>
          <p>Il DPO è contattabile all’indirizzo e-mail: dpo@veronafiere.eu</p>
          <p><strong>2. Finalità del trattamento, basi giuridiche e tempi di conservazione dei dati</strong></p>
          <p>I dati sono trattati per evadere la richiesta di contatto dell’utente, comunicando i suoi dati ed il suo messaggio all’azienda prescelta.</p>
          <p>La base giuridica del trattamento è l’esecuzione di un contratto di cui l’interessato è parte.</p>
          <p>I dati saranno conservati per il tempo necessario ad evadere la richiesta e comunque non oltre due anni, salvo ulteriori termini richiesti dalla legge. Decorsi tali termini di conservazione, i dati saranno distrutti, cancellati o resi anonimi, compatibilmente con le tempistiche tecniche di cancellazione e backup.</p>
          <p><strong>4. Conferimento dei dati</strong></p>
          <p>Il conferimento dei dati nel form è necessario per la corretta gestione della richiesta; pertanto, il mancato conferimento non permetterà invio e gestione della stessa.</p>
          <p><strong>5. Categorie di destinatari dei dati</strong></p>
          <p>Come indicato sopra, i dati saranno comunicati all’azienda con cui l’utente intende mettersi in contatto, che li tratterà in qualità di titolare autonomo.</p>
          <p>I dati potranno essere altresì trattati, per conto del Titolare, da soggetti terzi, designati come responsabili del trattamento ex art. 28 del GDPR, che svolgono attività funzionali alle finalità di cui sopra (es. fornitori di servizi IT, di servizi di customer care).</p>
          <p>Inoltre, i dati sono trattati dai dipendenti di Veronafiere - appartenenti alle funzioni aziendali deputate al perseguimento delle finalità sopraindicate - che sono stati espressamente autorizzati al trattamento e che hanno ricevuto adeguate istruzioni operative.</p>
          <p><strong>6. Trasferimenti di dati al di fuori dell’Unione Europea (“UE”)</strong></p>
          <p>Laddove la società destinataria del messaggio sia stabilita al di fuori dell’UE, i dati personali dell’utente saranno trasferiti in tale “paese terzo”. In tal caso, il trasferimento è necessario ai fini dell’evasione della richiesta di “contatto”, applicandosi, di conseguenza, la deroga di cui all’art. 49.1, lett. b del GDPR (ossia quando il trasferimento è necessario all'esecuzione di un contratto concluso tra l'interessato e il titolare del trattamento ovvero all'esecuzione di misure precontrattuali adottate su istanza dell'interessato).</p>
          <p><strong>7. Diritti dell’interessato</strong></p>
          <p>In relazione ai trattamenti dei dati personali effettuati dal Titolare, l’interessato – cioè il soggetto a cui i dati si riferiscono – può esercitare i diritti di cui agli artt. da 15 a 22 del GDPR, laddove applicabili, e, in particolare, può chiedere l’accesso ai dati che la riguardano e alle informazioni di cui all’art. 15 (finalità del trattamento, categorie di dati personali, etc.), la loro cancellazione nelle ipotesi previste dall’art. 17, la rettifica dei dati inesatti, l’integrazione dei dati incompleti, la limitazione del trattamento nei casi previsti dall’art. 18, oltre alla portabilità dei dati (cioè di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati, nonché, se tecnicamente fattibile, di trasmetterli ad altro titolare senza impedimenti) nel caso in cui il trattamento sia basato sul consenso o sul contratto e sia effettuato con strumenti automatizzati.</p>
          <p>Per l’esercizio dei diritti l’interessato può rivolgersi al Titolare inviando una comunicazione ai punti di contatto di cui al par. 1 oppure una e-mail all’indirizzo privacy@veronafiere.it.</p>
          <p>L’interessato ha il diritto di proporre reclamo all’Autorità di controllo competente nello Stato membro in cui risiede abitualmente o lavora o dello Stato in cui si è verificata la presunta violazione.</p>
        </div>
      ) : (
        <div>
          <h1>PRIVACY NOTICE PURSUANT TO ART. 13 OF THE REG. (EU) 2016/679 (“GDPR”) – “CONTACT”</h1>
          <p>Below Veronafiere S.p.A. (hereinafter referred to as “<strong>Veronafiere</strong>” or “<strong>Controller</strong>”) provides information about the processing of personal data provided in the relevant form on the online platform by users in order to be put in contact with the companies on such platform.</p>
          <p><strong>1. Identity ad contact details of data controller</strong></p>
          <p>The data controller is Veronafiere S.p.A., VAT n. 00233750231, Viale del Lavoro, 8, 37135 – Verona, tel. 045 8298111, e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, PEC <a href="mailto: mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>
          <p><strong>2. Contact details of data protection officer (dpo)</strong></p>
          <p>You can contact the DPO at the email address <a href="mailto:dpo@veronafiere.eu">dpo@veronafiere.eu</a></p>
          <p>3. Data processing purposes, legal basis and data retention</p>
          Data are processed in order to fulfil the users’ contact request, communicating their data and message to the company of their choice.
          The legal basis for processing is the performance of the contract to which the data subject is party.
          Data will be stored for the time necessary to process the request and in any case no longer than 2 years, unless further terms are required by law. Once such data retention term has expired, data will be destroyed or anonymized, compatibly with the technical times required for erasure and backup.
          <p><strong>4. The provision of the data</strong></p>
          The provision of data highlighted with an asterisk in the registration form is necessary for the proper management of the request; therefore, failure to provide data will not allow the sending and management of such request.
          <p><strong>5. Categories of recipients to whom the data may be disclosed</strong></p>
          Data may be disclosed to parties acting as controllers, such as public authorities and professional firms.
          Data may be processed, on behalf of the Controller, by third parties, appointed as Processors pursuant to art. 28 of the GDPR, carrying out activities that are functional to the services provided (e.g., IT services, customer care services),
          Furthermore, data are processed by Veronafiere employees who have been expressly authorised to process data for the above-mentioned purpose and have received adequate operating instructions.
          <p><strong>6. Data transfers outside the European Union ("EU")</strong></p>
          Where the company receiving the message is established outside the EU, personal data will be transferred to that "third country". In this case, the transfer is necessary for the purposes of processing the "contact" request, applying, consequently, the derogations set forth in Article 49.1, letter b of the GDPR (i.e., when the transfer is necessary for the performance of a contract between the data subject and the controller or the implementation of pre-contractual measures taken at the data subject’s request).
          <p><strong>7. Data subjects’ rights</strong></p>
          In relation to the processing carried out by the Controller, the data subject - i.e., the individual to whom the data refers - may exercise the rights referred to in articles 15 to 22 of the GDPR, where applicable, and, in particular, may:
          <ol>
            <li>obtain the confirmation as to whether or not personal data concerning him/her are being processed, and, where that is the case, access personal data and the information referred to in article 15 of the GDPR,</li>
            <li>obtain the rectification of inaccurate data,</li>
            <li>have incomplete data completed,</li>
            <li>obtain the erasure of data in the cases provided for by article 17 of the GDPR,</li>
            <li>obtain restriction of processing in the cases provided for by article 18 of the GDPR,</li>
            <li>where the processing is based on consent or contract and is carried out by automated means, to receive the data in a structured, commonly used and machine-readable format and to transmit those data to another controller without hindrance from the controller to which the personal data have been provided (the right to data portability).</li>
          </ol>
          <p>In order to exercise such rights, the data subject can contact the Controller by sending a communication to the contact points listed in paragraph 1 or an e-mail to <a href="mailto:privacy@veronafiere.it">privacy@veronafiere.it</a>.</p>
          <p>Data subjects shall have the right to lodge a complaint with the competent supervisory authority in the Member State of his or her habitual residence, place of work or place of the alleged infringement.</p>
        </div>
      )}
    </Container>
  );

}

export default Terms;
