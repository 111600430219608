import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import {getPermissions} from "../auth/selectors";

export const getRooms = createSelector(
  (state) => state.chat,
  ({ rooms, search }) => rooms.filter(room => search.trim() === '' || room.label.toLowerCase().indexOf(search.toLowerCase()) !== -1)
);

export const getActiveRooms = createSelector(
  (state) => state.chat,
  getRooms,
  (state, rooms) => rooms.filter(room => room.hasOwnProperty('roomId') && room.roomStatus !== 'CLOSED')
);

export const getClosedRooms = createSelector(
  (state) => state.chat,
  getRooms,
  (state, rooms) => rooms.filter(room => room.roomStatus === 'CLOSED')
);

export const getPendingRooms = createSelector(
  (state) => state.chat,
  (chat) => chat.waitingList
);

export const getCurrentId = createSelector(
  (state) => state.chat,
  chat => chat.current
);

export const getCurrent = createSelector(
  (state) => state.chat,
  chat => chat.current
);

export const getCurrentRoom = createSelector(
  (state) => state.chat,
  ({ rooms, waitingList, current }) => {
    if (current && current.type !== 'room') {
      return waitingList.find(room => current.id === room.id);
    } else if (current) {
      return rooms.find(room =>
        (current.type === 'user' && room.userId === current.id)
        || (current.type === 'company' && room.companyId === current.id)
        || (current.type === 'room' && room.roomId === current.id)
      );
    }
    return null;
  }
);

export const isSending = createSelector(
  (state) => state.chat,
  chat => chat.isSending
);

export const getMessages = createSelector(
  (state) => state.chat,
  getCurrentRoom,
  (chat, currentRoom) => {
    const isAgent = chat.attendee ? chat.attendee.attendeeType === 'AGENT' : false;
    return currentRoom ? (
      currentRoom.hasOwnProperty('message') ?
        [{
          self: !currentRoom.hasOwnProperty('roomId') && !isAgent,
          message: currentRoom.message,
          createdAt: currentRoom.createdAt
        }] :
        (chat.messages && chat.messages.hasOwnProperty(currentRoom.roomId) ? chat.messages[currentRoom.roomId] : [])
    ) : [];
    return [];
  }
);

export const getIsLoadingRooms = createSelector(
  (state) => state.chat,
  chat => chat.isLoadingRooms
);

export const isLoadingMessages = createSelector(
  (state) => state.chat,
  chat => chat.isLoadingMessages
);

export const getAttendee = createSelector(
  (state) => state.chat,
  chat => chat.attendee
);

export const getIsAgent = createSelector(
  getAttendee,
  attendee => attendee ? attendee.attendeeType === 'AGENT' : false
);

export const getActiveTab = createSelector(
  state => state.chat,
  chat => chat.tab
);

export const getSearchValue = createSelector(
  state => state.chat,
  ({search}) => search
);

export const getUnreadMessagesCount = (roomId = null) => createSelector(
  state => state.chat,
  (chat) => {
    return !chat.unreadMessages ? 0 : Object.keys(chat.unreadMessages).filter(tempRoomId => !roomId || (roomId && tempRoomId === roomId)).reduce((acc, tempRoomId) => acc + chat.unreadMessages[tempRoomId], 0)
  }
)


export const getCompanyListData = createSelector(
  state => state.chat,
  (chat) => Object.keys(chat.companyData)
);

export const getCompanyData = (companyId) => createSelector(
  state => state.chat,
  ({companyData}) => companyId && companyData.hasOwnProperty(companyId) ? companyData[companyId] : null
);

export const getCompanySubtitle = (companyId) => createSelector(
  state => state.chat,
  ({companyData}) => companyData.hasOwnProperty(companyId) ? companyData[companyId].lowertext : null
);

export const getActiveCompanies = createSelector(
  state => state.chat,
  chat => chat.activeCompanies
);
