import React, { useEffect, useState } from 'react';
import {Redirect, Route, Switch, useParams} from 'react-router-dom';
import {isEmpty} from "lodash";

import Homepage from "./Homepage";
import Inspiration from "./Inspiration";
import SocialWall from "./SocialWall";
import WinePeople from "./WinePeople";
import MarketTrends from "./MarketTrends";
import VinitalyWorld from "./VinitalyWorld";
import RecommendedService from "../../../services/RecommendedService";
import DataService from "../../../services/DataService";
import NewsService from "../../../services/NewsService";
import {useSelector} from "react-redux";
import {getUser} from "../../../store/auth/selectors";


export default function Home(){

  const [featured, setFeatured] = useState(null);
  const user = useSelector(getUser);
  let { id } = useParams();

  async function loadData() {

    try {

      await setFeatured({});
      const featuredList = await RecommendedService.getList(user ? user.id : null);
      const productIds = featuredList.reduce((acc, area) => [...acc, ...area.items.map(({item}) => item)], []);
      const productsArray = await DataService.getList({area: 'products', ids: productIds});
      const products = productsArray.reduce((acc, item) => ({...acc, [item.id]: item}), {})

      setFeatured({featuredList, products: products})

    } catch (e) {}

  }

  if (!featured) {

    loadData();

  }

  return (
    <Switch>
      <Route exact path='/:lang' render={() => <Homepage key={'home'} featured={featured} singleId={id} />}/>
      <Route exact path='/:lang/homepage/:id' render={() => <Homepage key={'home-detail'} featured={featured} singleId={id} />}/>
      <Route exact path='/:lang/inspiration/:id?' render={() => <Inspiration featured={featured} />}/>
      <Route exact path='/:lang/socialwall/:id?' render={() => <SocialWall />}/>
      <Route exact path='/:lang/winepeople/:id?' render={() => <WinePeople featured={featured} />}/>
      <Route exact path='/:lang/markettrends/:id?' render={() => <MarketTrends featured={featured} />}/>
      <Route exact path='/:lang/vinitalyworld/:id?' render={() => <VinitalyWorld featured={featured} />}/>
    </Switch>

  );

}
