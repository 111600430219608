import React from 'react'
import {Link, useParams} from 'react-router-dom'
import styled from 'styled-components'

import {useTheme} from './../../themes';

import Logo from "./../../components/App/Logo";
import ListButton from "./../../components/List/ListButton";

const Wrapper = styled.div`
  width: 100%;
  height: ${props => props.height.value + props.height.unit};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  ${props => props.background ? "background: " + props.background + ";" : ""}
`;

const Left = styled.div`
  flex-basis: 60px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: left;
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
`;
const Right = styled.div`
  flex-basis: 60px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
`;

function Header({hideListButton, area}) {
  const theme = useTheme();
  let {lang, subarea} = useParams();

  return (
    <Wrapper background={theme.topbar.mobile.background} height={theme.topbar.mobile.height}>
      <Left>
        {!hideListButton && (
          <ListButton
            area={area}
            subarea={subarea}
            size={{value: 40, unit: "px"}}
            color={theme.topbar.mobile.icon.normal.color}
            background={theme.topbar.mobile.icon.normal.background}
          />)
        }
      </Left>
      <Center>
        <Link to={"/" + lang}>
          <Logo
            variant={theme.topbar.mobile.logo.variant}
            width={theme.topbar.mobile.logo.width}
            height={theme.topbar.mobile.logo.height}/>
        </Link>
      </Center>
      <Right>
      
      </Right>
    </Wrapper>
  );
}

export default React.memo(Header)
