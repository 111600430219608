import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '../../themes'
import styled from 'styled-components';

const Wrapper  = styled.div`
  display: flex;
  flex-direction: row;
`;
const Link  = styled.a`
  display: ${ props => props.isEnabled ? "inline-block" : "none" };
  padding-left: ${props => props.spacing+"px"};

  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};


  color: ${props => props.normalTextColor};

  &:hover{
    color: ${props => props.hoverTextColor};
  }
`;

const Anchors = ({
  variant = "default",
  list,
  theme,
  onTabClick
}) => {
  return (
    <Wrapper>
      {list.map((item, index) => (
        <Link
          onClick={e => onTabClick(e, item.anchor)}
          key={index}
          href={"#"+item.anchor}
          isEnabled={item.enabled}
          spacing={theme.anchors.spacing}
          textFont={theme.anchors.link.textFont}
          textSize={theme.anchors.link.textSize}
          textWeight={theme.anchors.link.textWeight}
          textLineHeight={theme.anchors.link.textLineHeight}
          textLetterSpacing={theme.anchors.link.textLetterSpacing}
          textTransform={theme.anchors.link.textTransform}
          normalTextColor={theme.anchors.variant[variant].link.normal.textColor}
          hoverTextColor={theme.anchors.variant[variant].link.hover.textColor}

        >{item.name}</Link>
      ))}
    </Wrapper>
  )
}

Anchors.propTypes = {
  list: PropTypes.array
}

export default withTheme(Anchors)
