import React, { PureComponent } from 'react';
import styled from "styled-components";
import spinner from '../../../assets/loader.gif';

const Wrapper = styled.form`
  width: 100%;
`;

const Input = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  background: #EDEDED;
  border: none;
  border-radius: 10px;
  color: #000;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 15px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  border: 1px solid #63285B;
  width: 100px;
  height: 33px;
  background-image: ${props => props.isLoading ? 'url(' + spinner + ')' : ''};
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 16px auto;
  text-indent: ${props => props.isLoading ? '-9999px' : '0'};
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  color: #63285B;
  ${props => props.disabled ? 'opacity: 0.5;' : ''}
`;

const Waiting = styled.div`
  text-align: center;
`;

const Right = styled.div`
  text-align: right;
`;

export default class Textarea extends PureComponent{

  state = {
    value: ''
  };

  changeHandler = (e) => {
    this.setState({
      value: e.target.value
    })
  };
  submitHandler = (e) => {
    e.preventDefault();
    this.props.onSend(this.state.value);
    this.setState({
      value: ''
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isSending !== this.props.isSending && !this.props.isSending) {
      this.formRef.reset();
    }
  }

  render() {

    const { isSending, disabledSending } = this.props;
    const { value } = this.state;

    return (
      <Wrapper onSubmit={this.submitHandler} ref={ref => this.formRef = ref}>
        {disabledSending ? (
          <Waiting>
            Prima di inviare un nuovo messaggio devi aspettare che l'azienda risponda.
          </Waiting>
        ) : (
          <>
            <Input onKeyUp={this.changeHandler} placeholder="Scrivi qui il messaggio" />
            <Right>
              <Button isLoading={isSending} type="submit" disabled={isSending || value.trim() === ''}>Invia</Button>
            </Right>
          </>
        )}
      </Wrapper>
    );

  }

}


