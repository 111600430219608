import React, { useState } from 'react';
import styled from "styled-components";
import {useSelector} from 'react-redux'
import FsLightbox from "fslightbox-react";

import { LAYOUT_XS, LAYOUT_SM } from './../../settings';
import { getLayout } from "../../store/settings/selectors";

import FlowershelfGallery from "../Common/FlowershelfGallery";
import PropTypes from "prop-types";

const Wrapper  = styled.div`
`;

const Element  = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
`;
const Image  = styled.img`
	object-fit: cover;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;
const Description  = styled.div`
	position: absolute;
  bottom: -100%;
  left: 0px;
  width: 100%;
  height: auto;
  padding: 14px 20px;

  background-color: rgba(0,0,0,0.8);
  color: #fff;
  transition: all 0.25s ease-in-out;
`;


const Gallery = ({
   images,
	 videos,
	 lang
 }) => {
	const [lightboxIndex, setLightboxIndex] = useState(0);
	const [isLightboxOpen, setLightboxOpen] = useState(false);

	const layout = useSelector(getLayout);
	let arrFlowershelf = [];
	let arrLightbox = [];
	let arrCaptions = [];
	let arrTypes = [];
	if(videos.length > 0){
		videos.forEach((item, index) => {
			if(item){
				let thumb = item.rawOptions.thumbnail;
				let zoom = item.rawOptions.url;
				arrTypes.push("youtube");
				arrLightbox.push(zoom);
				arrCaptions.push(<>{item.options.lTitle[lang]}</>);

				let rowIndex = Math.floor(index/2);



				if(layout !== LAYOUT_XS && layout !== LAYOUT_SM){
					if(!(index !== 0 && index === images.length - 1 && index%2 === 0) && !arrFlowershelf[rowIndex]){
						arrFlowershelf[rowIndex] = [];
					}
				}
				let obj = {};
				obj.width = 800;
				obj.height = 600;
				// obj.content = <Element onClick={() => openLightbox(index)} ><Image src={thumb} alt={item.description} /><Description>{item.description}</Description></Element>;
				obj.content = <Element onClick={() => openLightbox(index)} ><Image src={thumb} alt={item.options.lTitle[lang]} /></Element>;

				if(layout !== LAYOUT_XS && layout !== LAYOUT_SM){
					if(index !== 0 && index === images.length - 1 && index%2 === 0){
						arrFlowershelf[rowIndex - 1].push(obj);
					}else{
						arrFlowershelf[rowIndex].push(obj);
					}
				}else{
					arrFlowershelf[index] = [];
					arrFlowershelf[index].push(obj);
				}
			}
		});
	}
	if(images.length > 0){
		images.forEach((item, index) => {
			if(item){
				let imageIndex = index + videos.length;
				let zoom = item.src;
				let thumb = item.src.replace("/gallery_zoom/","/gallery_thumb/");
				arrTypes.push("image");
				arrLightbox.push(zoom);
				arrCaptions.push(<>{item.options.lTitle ? item.options.lTitle[lang] : null}</>);

				let rowIndex = Math.floor(index/2);



				if(layout !== LAYOUT_XS && layout !== LAYOUT_SM){
					if(!(index !== 0 && index === images.length - 1 && index%2 === 0) && !arrFlowershelf[rowIndex]){
						arrFlowershelf[rowIndex] = [];
					}
				}
				let obj = {};
				obj.width = item.width;
				obj.height = item.height;
				// obj.content = <Element onClick={() => openLightbox(index)} ><Image src={thumb} alt={item.description} /><Description>{item.description}</Description></Element>;
				obj.content = <Element onClick={() => openLightbox(imageIndex)} ><Image src={thumb} alt={item.options.lTitle ? item.options.lTitle[lang] : null} /></Element>;

				if(layout !== LAYOUT_XS && layout !== LAYOUT_SM){
					if(index !== 0 && index === images.length - 1 && index%2 === 0){
						arrFlowershelf[rowIndex - 1].push(obj);
					}else{
						arrFlowershelf[rowIndex].push(obj);
					}
				}else{
					arrFlowershelf[index] = [];
					arrFlowershelf[index].push(obj);
				}
			}
		});
	}

	function openLightbox(i){
		setLightboxOpen(!isLightboxOpen);
		setLightboxIndex(i+1);
	}
	return (
		<Wrapper>
			<FlowershelfGallery
				images={arrFlowershelf}
				fullRows={true}
				gutter={0}
			/>
			<FsLightbox
				toggler={isLightboxOpen}
				sources={arrLightbox}
				types={arrTypes}
				slide={lightboxIndex}
				captions={arrCaptions}
			/>
		</Wrapper>
	);

}

Gallery.propTypes = {
	images: PropTypes.array
};

export default Gallery;
