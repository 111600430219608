import React from 'react';
import {Link} from 'react-router-dom';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getLocale} from "../../../store/localization/selectors";
import {useTheme} from "../../../themes";
import Image from "./../../../components/App/Image";
import Proportional from "./../../../components/App/Proportional";
import Loading from "./../../../components/App/Loading";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Upper = styled.div`
  color: #E5087E;
  font-size: 10px;
  margin-top: 5px;
`;
const Title = styled.div`
  color: #E5087E;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 600;
`;
const Lower = styled.div`
`;


export default function HomeProject({item = null, onClick = () => null}){

  const lang = useSelector(getLocale);
  const theme = useTheme();
  const project = item;

  return (
    <Wrapper>
      {project ?
        <Link onClick={onClick} to={"/"+lang+"/casehistories/"+project.id}>
          <Proportional ratio={1}>
            <Image
              src={project.options.coverImageId ? project.options.coverImageId.src : theme.placeholders.casehistory}
              height={{"value": 100, "unit": "%"}}
              width={{"value": 100, "unit": "%"}}
              fit={"cover"}
            />
          </Proportional>

          <Upper>{project.options.exhibitor.name}</Upper>
          <Title>{project.options.name[lang]}</Title>
          {/*<Lower>{ReactHtmlParser(project.lowertext)}</Lower>*/}
        </Link>
      :
        <Loading/>
      }
    </Wrapper>
  );

}
