import React, {Component} from 'react';
import {Provider} from 'react-redux';
import Store from './store/store';
import {ThemeProvider, themes} from './themes';
import Catalogue from './components/Layout/Catalogue';
import {DOMAINS} from "./domains";
import { BrowserRouter, Route } from "react-router-dom";

import './App.css';
import AnalyticsService from "./services/AnalyticsService";
import LetsFair from "./components/LetsFair/LetsFair";
// import BackendService from "./services/BackendService";

class App extends Component {

    state = {
        domain: null,
        layout: null
    };

    componentDidMount() {

        this.handleDetectDomain();

        window.addEventListener('beforeunload', (event) => {
            AnalyticsService.sessionEnd();
        });

    }

    componentWillUnmount() {
        console.log('disconnect');
    }

    handleDetectDomain() {

        this.setState({
            domain: window.location.hostname
        });

    }

    render() {

        const {domain} = this.state;
        const theme = domain ? themes[DOMAINS[domain].tenant] : null;

        return (
          <Provider store={Store.store}>
              {theme && (
                <>
                  <ThemeProvider theme={theme}>
                      <BrowserRouter>
                          <Route path="/:lang?" component={Catalogue} />
                      </BrowserRouter>
                  </ThemeProvider>
                </>
              )}
          </Provider>
        )

    }
}

export default App;
