import React, {Component} from 'react';
import {isArray, isEmpty, isEqual, omit} from 'lodash';
import {useSelector} from 'react-redux'
import {withTheme} from "../../themes";
import Button from './Button';
import styled from 'styled-components';
// import CloseButton from './CloseButton';
import GoBack from './GoBack';
import AnalyticsService from '../../services/AnalyticsService';
import DataService from "./../../services/DataService";

import Levels from './Levels';
import ConfirmButton from './ConfirmButton';
import './Filters.scss';
import {LevelLink} from "./types/LevelButton";
import {getChildFilters, propertiesToArray, getTranslation} from "../../helpers";

import {LAYOUT_XS, LAYOUT_SM} from './../../settings';


const HeaderText = styled.span`
  color: ${props => props.color};
  font-size: ${props => props.size}px;
  font-weight: ${props => props.weight};
`;


class Filters extends Component {

  constructor(props) {
    super(props);

    this.state = {
      structure: null,
      values: null
    }

    this.handleSelectFilter = this.handleSelectFilter.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleSelectFacet = this.handleSelectFacet.bind(this);
    this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
    this.handleGetFacets = this.handleGetFacets.bind(this);
    this.handleSearchFacets = this.handleSearchFacets.bind(this);
    this.handleSubareaClick = this.handleSubareaClick.bind(this);
  }

  componentDidMount() {

    this.props.onSetLevels({
      area: `${this.props.area}-${this.props.subarea}`,
      levels: [],
      levelsDepth: 0
    })

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    let treeLevel = 0;

    const prevArea = `${prevProps.area}-${prevProps.subarea}`;
    const newArea = `${this.props.area}-${this.props.subarea}`;

    if (prevArea !== newArea) {
      this.props.onSetLevels({
        area: `${this.props.area}-${this.props.subarea}`,
        levels: [],
        levelsDepth: 0
      })
    }

    if (
      (prevProps.data && !isEqual(prevProps.data.filters, this.props.data.filters)) ||
      (!isEqual(prevProps.filters.levels, this.props.filters.levels) && prevProps.filters.levels.length < this.props.filters.levels.length)
    ) {

      const filterItem = this.props.filters.levels.reduce((acc, item) => {
        let temp = acc.hasOwnProperty(item) ? acc[item] : (acc.hasOwnProperty('properties') ? acc.properties[item] : acc);
        if (temp.hasOwnProperty('filterOptions')) {
          temp = {...temp, field: item}
        }
        return temp;
      }, this.props.filtersSchema)

      if (filterItem.hasOwnProperty('field')) {

        let filter = null;
        this.handleGetFacets(filterItem, filter);


      }

    }

    /*
    if (
      (prevProps.data && !isEqual(prevProps.data.filters, this.props.data.filters)) ||
      (prevProps.filters.levels !== this.props.filters.levels && this.props.filters.levels.length > 1)) {
      const filtersStructure = this.getFilterStructure();
      let filterItem = filtersStructure;
      let lastIndex = 0;

      console.log(this.props.filters);

      for (let index = 0; index < this.props.filters.levels.length; index++) {

        // TODO: commentato per test
        // filterItem = filterItem[this.props.filters.levels[index]]
        // if (filterItem.hasOwnProperty('type') && filterItem.type === 'tree') {
        //   lastIndex = index;
        //   treeLevel = this.props.filters.levels.length - index - 1;
        //   break;
        // }
      }

      console.log(filterItem);

      if (filterItem.hasOwnProperty('type')) {
        let field = filterItem.field;
        let filter = null;
        if (filterItem.type === 'tree') {

          if (treeLevel > 0) {
            const filterName = (this.props.filters.levels.slice(0, treeLevel + 1).join('_')).concat('_', treeLevel - 1);
            filter = Object.assign({}, filter, {
              [filterName]: this.props.filters.levels[lastIndex + 1]
            });
          }

          field = `${field}.lvl${treeLevel}`

        }
        this.handleGetFacets(field, filter);
      }

    }

     */

  }

  handleSelectFilter(filter, fieldName, reset) {

    let levels;

    levels = reset ? [filter] : [...this.props.filters.levels, filter];

    this.props.onSetLevels({
      levels,
      levelsDepth: levels.length
    })

  }

  handleGoBack() {

    const {levelTransitionDuration} = this.props;
    let filterStructure = this.props.filtersSchema;

    let prevLevelDepth = this.props.filters.direct ? 0 : this.props.filters.levelsDepth > 0 ? this.props.filters.levelsDepth - 1 : 0;
    let prevLevels = [];
    let prevLevelSubfilters;

    if (prevLevelDepth > 0) {
      prevLevels = this.props.filters.levels.slice(0, -1);

      try {
        prevLevelSubfilters = prevLevels.reduce((list, item) => {
          return list[item]
        }, filterStructure);
      } catch (e) {

      }
      //
      // console.log("ZONA 1");
      // console.log(prevLevelDepth);
      // console.log(prevLevelSubfilters);
      if (prevLevelSubfilters) {

        // console.log("ZONA 2");
        if (prevLevelSubfilters && !prevLevelSubfilters.hasOwnProperty('field')) {
          // console.log("entro 1");
          if (Object.keys(prevLevelSubfilters).length === 1) {
            // console.log("entro 2");
            prevLevels = this.props.filters.levels.slice(0, -2);
            prevLevelDepth = prevLevels.length;
          }
        }
        // console.log(prevLevelDepth);

        for (let i = 0; i < prevLevels.length; i++) {
          if (filterStructure[prevLevels[i]] && filterStructure[prevLevels[i]].hasOwnProperty('default')) {
            filterStructure = filterStructure[prevLevels[i]]
          }
        }

        // if (Object.keys(filterStructure).length === 1) {
        //   prevLevelDepth--;
        //   prevLevels = prevLevels.slice(0, -1);
        // }

      }

    }

    this.props.onSetLevels({
      levels: this.props.filters.levels,
      levelsDepth: prevLevelDepth,
      direct: false
    });

    setTimeout(() => {
      this.props.onSetLevels({
        levels: prevLevels,
        levelsDepth: prevLevelDepth,
        direct: false
      })
    }, levelTransitionDuration * 2000);

  }

  handleSelectFacet({field, facet, type = 'array', remove}) {
    const {data, area} = this.props;

    let selected = data.filters;

    if (type === 'array') {

      if (selected && !selected.hasOwnProperty(field)) {
        selected = Object.assign({}, selected, {
          [field]: []
        });
      }

      if (selected && selected[field].indexOf(facet) !== -1) {

        const temp = selected[field].filter(item => item !== facet);
        if (temp.length === 0) {
          selected = omit(selected, [field])
          remove = true;
        } else {
          selected = Object.assign({}, selected, {
            [field]: temp
          });
          AnalyticsService.sendEventFilter(area, field, temp);
        }

      } else {

        selected = Object.assign({}, selected, {
          [field]: (selected && selected.hasOwnProperty(field)) ? [...selected[field], facet] : [facet]
        });
        AnalyticsService.sendEventFilter(area, field, facet);

      }

    } else if (type === 'tree') {

      if (selected && field.indexOf('_lv')) {
        let fieldName = field.split('_lv');
        const levelNumber = parseInt(fieldName[1]);
        fieldName = fieldName[0];

        Object.keys(selected).filter(key => {
          return key.indexOf(fieldName) === 0 && (parseInt(key.split('_lv')[1]) > levelNumber);
        }).map((key) => selected = omit(selected, [key]))

      }

      if ((selected && !selected.hasOwnProperty(field)) || facet !== null && !remove) {

        selected = Object.assign({}, selected, {
          [field]: facet
        });
        AnalyticsService.sendEventFilter(area, field, facet);

      } else {

        selected = omit(selected, [field])
        remove = true;

      }

    } else if (type === 'boolean') {

      if (!selected) selected = {};

      if (selected && !selected.hasOwnProperty(field)) {
        selected = Object.assign({}, selected, {
          [field]: facet
        });
        AnalyticsService.sendEventFilter(area, field, facet);
      } else {
        selected = omit(selected, [field])
        remove = true;
      }

    } else if (type === 'range') {

      selected = Object.assign({}, selected, {
        [field]: facet
      });
      AnalyticsService.sendEventFilter(area, field, facet);

    }
    this.props.onSetFilters({type, field, facet, filters: selected, remove});


  }

  handleRemoveFilter(item) {

    let {filters} = this.props.data;

    const filterValues = filters[item.key];

    if (isArray(filterValues)) {
      const items = filterValues.filter(value => value !== item.value);
      if (items.length) {
        filters = Object.assign({}, filters, {
          [item.key]: items
        })
      } else {
        filters = omit(filters, [item.key])
      }
    } else {
      let keyVal = item.key;
      if (item.key.indexOf('_lv') !== -1) {
        keyVal = item.key.split('_lv')[0];
      }
      Object.keys(filters).filter(key => key.indexOf(`${keyVal}_lv`) !== -1).map(key => {
        filters = omit(filters, [key])
      })
      filters = omit(filters, [item.key])
    }

    this.props.onSetFilters({filters, remove: true});

  }

  getSubareas() {

    const {area, subarea, config} = this.props;
    const {subareas} = config.areas[area];
    return Object.keys(subareas).filter(item => subareas[item].enable);

  }

  hasSubareas() {
    return this.getSubareas().length > 1;

  }

  async getFilterStructure() {

    const {area, subarea, config} = this.props;

    const {subareas} = config.areas[area];
    const keys = Object.keys(subareas);
    const defaultSubarea = keys.find(keyItem => subareas[keyItem].default);

    // const filters = !subarea || keys.indexOf(subarea) === -1 ? subareas[defaultSubarea].filters : subareas[subarea].filters;
    //
    // let filterStructure = Object.keys(filters)
    //   .filter(filterKey => Object.keys(filters[filterKey]).filter(subfilterKey => filters[filterKey][subfilterKey].field !== '-').length)
    //   .reduce((obj, key) => Object.assign({}, obj, {[key]: filters[key]}), {});
    // // console.log(filterStructure);

    let structure = await DataService.getFiltersStructure(area);

    return structure;
  }

  handleGetFacets(field, filters) {

    this.props.onGetFacets(field, filters);

  }

  handleSearchFacets(key, searchString) {

    searchString = searchString.length > 2 ? searchString : '';
    this.props.onGetFacets(key, null, searchString);

  }

  handleSubareaClick(subareaKey) {

    if (subareaKey === this.props.subarea) {
      this.props.onCloseFilters();
    }

  }

  render() {

    const {
      isSubareaOpen,
      levelTransitionDuration,
      width,
      isMobile,
      translations,
      area,
      subarea,
      data,
      onCloseFilters,
      onResetFilters,
      filtersSchema
    } = this.props;
    const {levels, levelsDepth} = this.props.filters;

    let filtersTitle = null;
    let areaTitle = null;

    if (levelsDepth === 0 || this.props.filters.area !== `${area}-${subarea}`) {
      if (this.hasSubareas()) {
        filtersTitle = getTranslation(translations, ['UI', `_label-${area}-${subarea}`]);
      } else {
        filtersTitle = getTranslation(translations, ['UI', `_label-${area}`]);
      }
      areaTitle = getTranslation(translations, ['UI', `_label-${area}`]);
    } else if (levelsDepth === 1) {
      filtersTitle = getTranslation(translations, [`filter-${area}`, `_filter-label-${levels[levelsDepth - 1]}`]); //translations && translations[`filter-${area}`] && translations[`filter-${area}`][`_filter-label-${levels[levelsDepth - 1]}`] ? translations[`filter-${area}`][`_filter-label-${levels[levelsDepth - 1]}`] : `@${levels[levelsDepth - 1]}`;
    } else if (levelsDepth > 1) {
      filtersTitle = getTranslation(translations, [`filter-${area}`, `_filter-label-${levels[levelsDepth - 1]}`]); //translations && translations[`filter-${area}`] && translations[`filter-${area}`][`_filter-label-${levels[levelsDepth - 1]}`] ? translations[`filter-${area}`][`_filter-label-${levels[levelsDepth - 1]}`] : `@${levels[levelsDepth - 1]}`;

      let currentLevel = levels[levelsDepth - 1];
      if (currentLevel.indexOf('_lv') !== -1) {
        currentLevel = currentLevel.split('_lv');
        if (currentLevel[1] > 1)
          currentLevel = `${currentLevel[0]}_lv${currentLevel[1] - 1}`
        else
          currentLevel = currentLevel[0];

        if (data.filters[currentLevel]) {
          filtersTitle = data.filters[currentLevel].split(' >> ');
          filtersTitle = filtersTitle[filtersTitle.length - 1];
        }
      }
    }

    return (
      <div className={`Filters ${isSubareaOpen ? 'Filters--show-subareas' : ''}`}>
        <div className={'Filters__Panel Filters__PanelSubareas'}>
          <div className={'Filters__Header'}>
            <div className={'Filters__HeaderMain'}>
              <div className={'Filters__HeaderMainLeft'}/>
              <div className={'Filters__HeaderMainCenter'}>
                <HeaderText color={this.props.theme.filters.bar.textColor} size={this.props.theme.filters.bar.textSize} weight={this.props.theme.filters.bar.textWeight}>{areaTitle}</HeaderText>
              </div>
              <div className={'Filters__HeaderMainRight'}>
                <Button icon={'closeFilters'} onClick={e => onCloseFilters(false)}/>
              </div>
            </div>
          </div>
          {/*<div className={'Filters__Content Filters__ContentSubareas'}>
            {this.getSubareas().map(key => <LevelLink onClick={e => this.handleSubareaClick(key)} to={`/${language}/${area}/${key}`} key={`filters-subarea-${key}`} label={translations["UI"][`_label-${area}-${key}`] ? translations["UI"][`_label-${area}-${key}`] : key}/>)}
          </div>*/}
        </div>
        <div className={'Filters__Panel'}>
          <div className={'Filters__Header'}>
            <div className={'Filters__HeaderMain'}>
              <div className={'Filters__HeaderMainLeft'}>
                {/*this.hasSubareas() && levelsDepth === 0 ? <GoBack onClick={onShowSubareas}/> : null*/}
                {levelsDepth > 0 ? <GoBack onClick={this.handleGoBack}/> : null}
              </div>
              <div className={'Filters__HeaderMainCenter'}>
                <HeaderText color={this.props.theme.filters.bar.textColor} size={this.props.theme.filters.bar.textSize} weight={this.props.theme.filters.bar.textWeight}>{filtersTitle}</HeaderText>
              </div>
              <div className={'Filters__HeaderMainRight'}>
                {data && isEmpty(data.filters) ? <Button icon={'closeFilters'} onClick={onCloseFilters}/> :
                  <Button icon={'clearFilters'} onClick={onResetFilters}/>}
              </div>
            </div>
          </div>
          <div className={'Filters__Content'}>
            {area ?
              <Levels
                area={area}
                isMobile={isMobile}
                facets={data.facets}
                transitionDuration={levelTransitionDuration}
                width={width}
                onSelect={this.handleSelectFilter}
                onSelectFacet={this.handleSelectFacet}
                onGetFacets={this.handleGetFacets}
                levels={levels}
                depth={levelsDepth}
                filtersSchema={filtersSchema}
                filtersValues={this.state.values}
                translations={translations}
                selected={data.filters}
                onRemoveFilter={this.handleRemoveFilter}
                onSearchFacet={this.handleSearchFacets}
              />
              : null}
          </div>
          {this.props.layout === LAYOUT_XS || this.props.layout === LAYOUT_SM ?
            <div className={'Filters__Footer'}>
              {(data) && (
                <ConfirmButton
                  hideButton={!isMobile}
                  total={data.count_initial}
                  current={data.count_filters}
                  text={translations["UI"]["Filter"]}
                  onClick={() => this.props.onCloseFilters()}
                />
              )}
            </div>
          : null}
        </div>
      </div>
    );

  }


}


export default withTheme(Filters);
