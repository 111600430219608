import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import configuration from '../../data/tenants/configuration'
import {addLevel, init, setArea, setLevels, setResultsType, toggleFilters, toggleModal, toggleSubareas, showPlansModal, showFullModal, hideFullModal} from './actions'
import {check} from "../auth/actions";
import {setList as companiesSetList} from "../companies/actions";
import {setList as productsSetList} from "../products/actions";
import {setList as eventsSetList} from "../events/actions";
import { setList as casehistoriesSetList } from "../casehistories/actions";

import {getArea, getSubarea} from "./selectors";

function* initSaga({ payload }) {
  try {
    const config = yield call([configuration, configuration.init]);
    const defaultArea = Object.keys(config.areas).find(areaKey => config.areas[areaKey].default);
    const defaultSubarea = Object.keys(config.areas[defaultArea].subareas).length > 1 ? Object.keys(config.areas[defaultArea].subareas).find(subareaKey => config.areas[defaultArea].subareas[subareaKey].default) : null;
    yield put(setArea.trigger(defaultArea, defaultSubarea));
    yield put(check.trigger());
    yield put(init.success(config))

  } catch(e) {
    console.error(e)
    yield put(init.failure("Failed"))
  }
}

function* areaSaga({ payload }) {
  try {

    yield put(setArea.trigger(payload))

  } catch(e) {
    console.error(e)
    yield put(setArea.failure("Failed"))
  }
}


function* toggleFiltersSaga({ payload }) {

  yield put(toggleFilters.trigger(payload))

}

function* toggleSubareaSaga({ payload }) {

  yield put(toggleSubareas.trigger(payload))

}

function* toggleModalSaga({ payload }) {

  yield put(toggleModal.trigger(payload))

}

function* setLevelsSaga({ payload }) {

  yield put(setLevels.trigger(payload))

}

function* addLevelSaga({ payload }) {

  yield put(addLevel.trigger(payload))

}

function* showPlansModalSaga() {

  yield put(toggleModal.trigger('plans'))

}
//
// function* showFullModalSaga({ payload }) {
//   yield put(showFullModal.trigger(payload))
// }
// function* hideFullModalSaga() {
//   yield put(hideFullModal.trigger())
// }

function* setResultsTypeSaga(data) {
  const area = yield select(getArea);
  const subarea = yield select(getSubarea);
  yield put(setResultsType.success(data.payload));
  if (area === 'companies') {
    yield put(companiesSetList.trigger({ page:0, reset: true, subarea }));
  } else if(area === 'products') {
    yield put(productsSetList.trigger({ page:0, reset: true, subarea }));
  } else if(area === 'events') {
    yield put(eventsSetList.trigger({ page:0, reset: true, subarea }));
  } else if(area === 'casehistories') {
    yield put(casehistoriesSetList.trigger({ page:0, reset: true, subarea }));
  }

}

export default function* root() {
  yield all([
    yield takeLatest([init.TRIGGER], initSaga),
    yield takeLatest([setResultsType.TRIGGER], setResultsTypeSaga),
    yield takeLatest([showPlansModal.TRIGGER], showPlansModalSaga),
    // yield takeLatest([showFullModal.TRIGGER], showFullModalSaga),
    // yield takeLatest([hideFullModal.TRIGGER], hideFullModalSaga),
  ])
}
