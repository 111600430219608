import React from 'react';
import {Link} from 'react-router-dom';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getLocale} from "../../../store/localization/selectors";
import {useTheme} from "../../../themes";
import Image from "./../../../components/App/Image";
import Proportional from "./../../../components/App/Proportional";
import Loading from "./../../../components/App/Loading";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #E5087E;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 10px;
  margin-top: 10px;
`;
const ImageCnt = styled.div`
  border-radius: 5px;
  overflow: hidden;
`;
const Upper = styled.div`
  color: #E5087E;
  font-size: 10px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;
const Title = styled.div`
  color: #E5087E;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 10px;
`;
const Lower = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

export default function HomeProduct({
  image = null,
  abstract = null,
  content = null,
  ctaText = null,
  ctaLink = null,
  onClick = () => null
}){

  const lang = useSelector(getLocale);
  const theme = useTheme();

  return (
    <Wrapper>
      <a href={ctaLink}>
        <ImageCnt>
          <Proportional ratio={1}>
            <Image
              src={image ? image : theme.placeholders.product}
              height={{"value": 100, "unit": "%"}}
              width={{"value": 100, "unit": "%"}}
              fit={"cover"}
            />
          </Proportional>
        </ImageCnt>
        <Upper>{abstract}</Upper>
        <Title>{ctaText}</Title>
        {/*<Lower>{ReactHtmlParser(content)}</Lower>*/}
      </a>
    </Wrapper>
  );

}
