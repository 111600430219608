import React from 'react';
import styled from "styled-components";
import {chatReadableDate} from "../../../helpers";

const Wrapper = styled.div`
  margin-bottom: 25px;
  ${({self}) => self ? 'padding-left: 30%;' : 'max-width: 70%;'}
  ${({self}) => self ? 'text-align: right;' : ''}
`;

const Inner = styled.div`
  display: inline-block;
  border-radius: 10px;
  background: ${({self}) => self ? '#703E6A' : '#F2F4F4'};
  color: ${({self}) => self ? '#fff' : '##322A5D'};
  box-shadow: 0px 1px 1px #0000003E;
  padding: 15px 25px 15px 20px;
  
`;

const Time = styled.div`
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #000;
  text-transform: capitalize;
`;

const Original = styled.div`
  font-size: 12px;
  margin-top: 10px;
  color: #000;
`;

export default function Message({ message = '', original = null, creationTimestamp = null, self = false, senderType = '' }) {

  return (
    <Wrapper self={self || senderType === 'USER'}>
      <Time>{chatReadableDate(creationTimestamp)}</Time>
      <Inner self={self || senderType === 'USER'}>{message}</Inner>
      {original && (<Original>{original}</Original>)}
    </Wrapper>
  );
}

