import ReactGA from 'react-ga';
import {from, of, Subject} from 'rxjs';
import {
  concatMap,
  delay,
} from 'rxjs/operators';

/*
  Nomenclatura 24c:
  [x] Home: home
  [x] Lista espositori “Tutti”: exhibitors-list
  [x] Lista espositori “Preferiti”: exhibitors-favorites
  [x] Lista espositori “Recenti”: exhibitors-recent
  [x] Lista prodotti “Tutti”: products-list
  [x] Lista prodotti “Preferiti”: products-favorites
  [x] Lista prodotti “Recenti”: products-recent
  [x] Lista progetti “Tutti”: projects-list
  [x] Lista progetti “Preferiti”: projects-favorites
  [x] Lista progetti “Recenti”: projects-recent
  [x] Lista eventi “Tutti”: events-list
  [x] Lista eventi “Preferiti”:  favorites-events
      Lista eventi “Passati”: past-events
      Ricerche salvate: favorites-searches
  [x] Lista memo: memos-list
  [x] Lista bcard: bcards-list
      Lista notifiche: notifications-list
      Miei biglieti: my-ticket-list
  [x] Dettaglio espositore: exhibitors-detail
  [x] Dettaglio prodotto: products-detail
  [x] Dettaglio progetto: projects-detail
  [x] Dettaglio evento: events-detail
      Dettaglio memo: memos-detail
      Modifica memo: memos-edit
      Generazione bcard: bcards-generator
      Lettore bcard: bcards-reader
      Lettore biglietto: my-ticket-reader
      Dettaglio biglietto: my-ticket-detail
      Dettaglio bcard: bcards-detail
      Dettaglio utente edit bcard: user#business-card
      Dettaglio utente edit profile: user#profile
      Dettaglio utente edit premium: user#premium
      Impostazioni: settings
  [x] Chat: chat

 */


class AnalyticsService {

  language = 'it';
  awsAnalytics = null;
  sessionStart = null;
  stream$ = new Subject();
  stream = null;

  constructor() {

    this.stream$.subscribe((value) => {

      let leaveValue = null

      if (this.stream) {
        const end_ts = new Date().getTime();
        leaveValue = {
          name: this.stream.name,
          id: this.stream.id,
          start_ts: this.stream.timestamp,
          end_ts: end_ts,
          duration: end_ts - this.stream.timestamp,
          pageAction: 'leave',
          lang: this.stream.lang
        };
        if(this.awsAnalytics) {
          this.awsAnalytics.page(leaveValue)
        }
        // console.log('leave', leaveValue);
      }

      if (this.awsAnalytics) {
        this.awsAnalytics.page(value)
      }

      // console.log('enter', value)

      this.stream = {...value, timestamp: new Date().getTime()}

    })

  }

  setConfig(codes, language = 'it') {
    let settings = [];
    codes.forEach((item, i) => {
      settings.push({
        trackingId: item
      });
    });
    this.language = language;
    ReactGA.initialize(settings, {alwaysSendToDefaultTracker: false});
  }

  setAwsAnalticsConfig(tenant, config) {

    if (tenant && config) {

      tenant = tenant.toLowerCase()

      if (tenant === 'mdm') tenant = 'mmp'; // nomenclatura 24c

      const settings = {
        contextData: {
          app: "web",
          platform: window?.navigator?.userAgent
        },
        params: {
          app: "web",
          env: config.area,
          exhibition: tenant,
          source: 'web'
        }
      };

      console.log('analytics settings', settings);

      this.awsAnalytics = new window.AnalyticsClient(settings);

      this.sessionStart = new Date().getTime();
      if (this.awsAnalytics) {
        this.awsAnalytics.track('app_start');
      }
    }
  }

  sendPageView({pathname = null, name = null, id = null, leave = false, lang = 'it', listType = null}) {

    if (name === 'companies') name = 'exhibitors';
    if (name === 'casehistories') name = 'projects';

    if(['exhibitors', 'products', 'projects', 'events'].indexOf(name) !== -1) {
      if (listType === 'favorites')
        name = `${name}-favorites`
      else if (listType === 'recents')
        name = `${name}-recent`
      else if(id)
        name = `${name}-detail`;
      else
        name = `${name}-list`;
    }

    this.language = lang;

    if (!leave && pathname) {
      ReactGA.pageview(pathname);
    }

    this.stream$.next({
      name,
      id,
      pageAction: leave ? 'leave' : 'enter',
      lang
    });

  }

  sendEventSearch(area, value) {

    if (value.length > 0) {

      ReactGA.event({
        category: 'Search By',
        action: area,
        label: 'Text',
        value: value
      });

      if (this.awsAnalytics) {
        this.awsAnalytics.track('search', {
          area,
          query: value,
          lang: this.language
        })
      }

    }

  }

  sendEventFilter(area, field, value) {

    ReactGA.event({
      category: 'Search By',
      action: area,
      label: field,
      value: value
    });

    if (this.awsAnalytics) {
      this.awsAnalytics.track('list_fiter', {
        area,
        field,
        value,
        lang: this.language
      })
    }

  }

  sendEventShowFilters(area) {

    ReactGA.event({
      category: 'Show filters',
      action: area,
      label: 'Show'
    });

    if (this.awsAnalytics) {
      this.awsAnalytics.track('show_filters', {
        area,
      })
    }

  }

  sendEventShowMore() {

    ReactGA.event({
      category: 'Show more',
      action: 'Show',
      label: 'Show'
    });

  }

  companyContacts({area, company, email}) {

    if (this.log) {
      // console.log('ANALYTICS EVENTO INVIO MAIL FORM');
    }

    ReactGA.event({
      category: 'Contact',
      action: 'companies',
      label: company,
      value: email
    });

    if (this.awsAnalytics) {
      this.awsAnalytics.track('company_contact', {
        area,
        company,
        email
      })
    }
  }

  sessionEnd() {
    const end_ts = new Date().getTime()
    if (this.awsAnalytics) {
      this.awsAnalytics.track('app_close', {
        start_ts: this.sessionStart,
        end_ts: end_ts,
        duration: end_ts - this.sessionStart
      })
    }
  }

}

export default new AnalyticsService();
