import React, {Component} from "react";
import {isEmpty} from 'lodash';
import LevelButton from './types/LevelButton';
import Button from './types/Button/Button';
import {Levels as Wrapper, Level, LevelButtonContainer} from "./types/styled";
import List from './types/List';
import Tree from "./types/Tree";
import Tags from "./Tags";
import {getTranslation} from "../../helpers";

class Levels extends Component {

  constructor(props) {
    super(props);
    this.props = props;

    this.handleSearchInput = this.handleSearchInput.bind(this);
  }

  handleSearchInput(ref) {
    this.$ref = ref;
  }

  componentDidUpdate(prevProps) {

    if (!isEmpty(prevProps.selected) && isEmpty(this.props.selected) && this.$ref) {
      this.$ref.value = '';
    }

  }

  renderLevel(key, depthLevel) {

    const {
      level,
      filtersSchema,
      translations,
      levels,
      onSelect,
      onSelectFacet,
      facets,
      selected,
      onSearchFacet,
      onGetFacets,
      filtersValues,
      area
    } = this.props;
    let treeName = null;
    let treeLevel = 0;
    let filter = filtersSchema;
    let lastIndex = 0;

    if (!isEmpty(filter)) {

      for (let i = 0; i < depthLevel + 1; i++) {

        if (filter.hasOwnProperty('properties')) {
          filter = filter.properties[levels[i]]
        } else if (!filter.hasOwnProperty('filterOptions')) {
          filter = filter[levels[i]]
        }
      }
    }

    if (filter && !filter.hasOwnProperty("filterOptions")) {

      return Object.keys(filtersSchema[levels[0]].properties).map((subKey, index) => {
        const filter = filtersSchema[levels[0]].properties[subKey];
        if (filter.filterOptions.type === 'list') {

          const label = getTranslation(translations, [`filter-${area}`, `_filter-label-${subKey}`]); //translations && translations[`_filter-label-${subKey}`] ? translations[`_filter-label-${subKey}`] : `@${subKey}`;
          return (
            <LevelButtonContainer key={`filter-${subKey}-${index}`}>
              <LevelButton onClick={e => onSelect(subKey, filter)} label={label}/>
            </LevelButtonContainer>
          );

        } else if (filter.filterOptions.type === 'boolean') {
          //
          const filterKey = [...levels, subKey].join('_');
          const label = getTranslation(translations, [`filter-${area}`, `_filter-label-${subKey}`]); //translations && translations[`_filter-label-${subKey}`] ? translations[`_filter-label-${subKey}`] : `@${subKey}`;
          const active = selected.hasOwnProperty(filterKey);
          let count = null;

          if (!facets.data.hasOwnProperty(filter.field)) {
            onGetFacets(filter.field);
          } else {
            count = 0;
            const facet = facets.data[filter.field].filter(item => item.value === 'true');
            if (facet.length) {
              count = facet[0].count;
            }
          }
          return (
            <LevelButtonContainer key={`filter-${subKey}-${index}`}>
              <Button active={active} count={count} onClick={e => onSelectFacet({field: filterKey, facet: !active, type: 'boolean'})} label={label}/>
            </LevelButtonContainer>
          );

        } else if (filter.filterOptions.type === 'tree') {

          const label = getTranslation(translations, [`filter-${area}`, `_filter-label-${subKey}`]); //translations && translations[`_filter-label-${subKey}`] ? translations[`_filter-label-${subKey}`] : `@${subKey}`;

          return (
            <LevelButtonContainer key={`filter-${subKey}-${index}`}>
              <LevelButton onClick={e => onSelect(subKey, filter)} label={label}/>
            </LevelButtonContainer>
          );

        }

      })

    } else {

      if (filter) {

        const field = levels[depthLevel];
        const type = filter.filterOptions.type;
        const data = facets.data.hasOwnProperty(field) ? facets.data[field] : [];

        let filterKey = field;
        const selectedFilters = selected && selected.hasOwnProperty(filterKey) ? selected[filterKey] : null;

        if (type === 'list') {

          return (
            <List
              key={`${field}-facets`}
              field={field}
              searchable={filter.filterOptions.searchable}
              data={data}
              onClick={value => onSelectFacet({field: filterKey, facet: value})}
              onSearch={value => onSearchFacet(field, value)}
              selected={selectedFilters}
              getInputRef={this.handleSearchInput}
            />
          );

        } else if (type === 'tree') {

          return (
            <Tree
              levels={levels}
              level={depthLevel}
              key={`${field}-facets`}
              field={field}
              searchable={filter.searchable}
              data={data}
              onSubTreeClick={subkey => {
                onSelect(subkey)
              }}
              onFacetClick={({key, value, remove}) => onSelectFacet({field: key, facet: value, type: 'tree', remove})}
              selected={selectedFilters}
              onSearch={value => onSearchFacet(`${field}.lvl${depthLevel}`, value)}
              filterKey={filterKey}
              getInputRef={this.handleSearchInput}
            />
          );

        } else if (type === 'range') {

          //     const selectedFilters = selected && selected.hasOwnProperty(filterKey) ? selected[filterKey] : null;
          //
          //     const values = sortArrayNumbers(data.map(item => parseInt(item.value)));
          //     const minValue = data.length ? values[0] : null;
          //     const maxValue = data.length ? values.slice(-1)[0] : null;
          //     return data.length ?
          //       <RangeSlider useLogarithmic={maxValue - minValue > 100000} value={selectedFilters} onChange={values => onSelectFacet({field: filterKey, facet: values.join(' - '), type: 'range'})}
          //                    minValue={values[0]} maxValue={values.slice(-1)[0]}/> : <p style={{paddingLeft: 20, paddingRight: 20}}>Dati non disponibili.</p>;

        }
      }

    }

    return null;

  }

  renderFirstLevel(item) {

    const {filters, translations, selected, onRemoveFilter, onSelect, onSelectFacet} = this.props;
    const filterItem = filters[item];
    const tags = !isEmpty(selected) ?
      <Tags selected={selected} filter={item} translations={translations} onRemoveFilter={onRemoveFilter}/> : null;

    if (filterItem.hasOwnProperty('type') && filterItem.type === 'boolean') {
      const value = selected && selected.hasOwnProperty(item) && selected[item] === true;
      return <LevelButton tags={null} key={`filters-level-0-${item}`}
                          onClick={() => onSelectFacet({field: item, facet: true, type: 'boolean'})}
                          label={getTranslation(translations, [`_filter-label-${item}`])} hideArrow={true}
                          active={value}
                          count={3}/>
    } else {
      return <LevelButton key={`filters-level-0-${item}`} tags={tags} onClick={e => onSelect(item, filterItem, true)}
                          label={getTranslation(translations, [`_group-${item}`])}/>;
    }

  }


  render() {

    const {
      isMobile,
      transitionDuration,
      width,
      filtersSchema,
      translations,
      area,
      depth,
      levels,
      onSelect,
      selected,
      onRemoveFilter,
      onSelectFacet
    } = this.props;
    return (
      <Wrapper
        transitionDuration={transitionDuration}
        width={width}
        isMobile={isMobile}
        depth={depth}
      >
        <Level isMobile={isMobile} width={width} key={'filters-level-0'}>
          {filtersSchema && Object.keys(filtersSchema).map((item, index) => {

            const filterItem = filtersSchema[item];

            const filterSelected = !isEmpty(selected) ?
              Object.keys(selected).filter(selectedKey => filtersSchema[item].childs.indexOf(selectedKey) !== -1).reduce((acc, selectedKey) => ({
                ...acc,
                [selectedKey]: selected[selectedKey]
              }), {})
              : null;

            if (filterItem.hasOwnProperty('filterOptions') && filterItem.filterOptions.type === 'boolean') {

              const label = getTranslation(translations, [`filter-${area}`, `_filter-label-${item}`]);
              const active = filterSelected && filterSelected.hasOwnProperty(item) && filterSelected[item];

              return (
                <LevelButtonContainer key={`filters-level-0-${index}`}>
                  <LevelButton onClick={e => onSelectFacet({field: item, facet: !active, type: 'boolean'})} hideArrow={true} active={active} label={label}/>
                </LevelButtonContainer>
              );

            }

            return (
              <LevelButton key={`filters-level-0-${index}`} tags={!isEmpty(filterSelected) ?
                <Tags selected={filterSelected} filter={item} translations={translations}
                      onRemoveFilter={onRemoveFilter}/> : null} onClick={e => onSelect(item, filtersSchema[item], true)}
                           label={getTranslation(translations, [`filter-${area}`, `_filter-label-${item}`])}/>
            )
          })}
        </Level>
        {levels.map((item, depthLevel) => <Level isMobile={isMobile} key={`filters-level-${depthLevel + 1}`}
                                                 width={width}>{this.renderLevel(item, depthLevel)}</Level>)}
      </Wrapper>
    );

  }

}

export default Levels;
