import React, { useState } from 'react';
import { useSelector } from "react-redux";
import styled from 'styled-components';
import { withTheme } from './../../themes';
import { getLayout } from './../../store/settings/selectors';
import { getTranslations, getLocale } from "./../../store/localization/selectors";
import imageSuggestions from "./../../tenants/VIR/assets/wizard-suggestions.svg";

import {LAYOUT_XS, LAYOUT_SM} from './../../settings';

import Logo from './../App/Logo';
import Image from "./../../components/App/Image";
import Proportional from "./../../components/App/Proportional";

const Wrapper  = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;
const Container  = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% * 5);
  height: calc(100% - 80px);
`;
const Mover  = styled.div`
  position: absolute;
  top: 0px;
  left: calc(calc(100% / 5) * -${props => props.offset});
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: all 0.15s ease-in-out;
`;
const Page  = styled.div`
  width: calc(100% / 5);
  padding: 50px;
`;
const Dots  = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Dot  = styled.div`
  margin: 0px 10px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${props => props.isActive ? "#7B3572" : "#dedede"};
  cursor: pointer;
`;


const Row = styled.div`
  display: flex;
  height: 100%;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
  align-items: center;

`;
const Col = styled.div`
  padding: 0px 12px;
  width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 50 : 100}%;
`;

const Title = styled.div`
  color: #7B3572;
  font-size: 24px;
  padding-top: 30px;
  font-weight: bold;
`;
const Text = styled.div`
  color: #585858;
  font-size: 14px;
  padding-top: 20px;
`;
const Figure = styled.div`
  width: 60%;
  margin: 0 auto;
`;
const Circle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  background-color: #efe7ed;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;


const Wizard = ({
  theme,
  tab
}) => {
  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);

  const tabs = ["companies","products","events","suggestions"];
  const [offset, setOffset] = useState(tabs.indexOf(tab) > -1 ? tabs.indexOf(tab) : 0);

  return (
    <Wrapper>
      <Container>
        <Mover offset={offset}>
          <Page>
            <Row layout={layout}>
              <Col layout={layout}>
                <Logo height={{value: 50, unit: "px"}} variant="default" />
                <Title>{translations["UI"]["_wizard-title-companies"]}</Title>
                <Text>{translations["UI"]["_wizard-text-companies"]}</Text>
              </Col>
              <Col layout={layout}>
                <Figure>
                  <Proportional ratio={1}>
                    <Circle>
                      <Image width={{"value": 100, "unit": "%"}} src={theme.idlepage.image["companies"]} alt="Idle"/>
                    </Circle>
                  </Proportional>
                </Figure>
              </Col>
            </Row>
          </Page>
          <Page>
            <Row layout={layout}>
              <Col layout={layout}>
                <Logo height={{value: 50, unit: "px"}} variant="default" />
                <Title>{translations["UI"]["_wizard-title-products"]}</Title>
                <Text>{translations["UI"]["_wizard-text-products"]}</Text>
              </Col>
              <Col layout={layout}>
                <Figure>
                  <Proportional ratio={1}>
                    <Circle>
                      <Image width={{"value": 100, "unit": "%"}} src={theme.idlepage.image["products"]} alt="Idle"/>
                    </Circle>
                  </Proportional>
                </Figure>
              </Col>
            </Row>
          </Page>
          <Page>
            <Row layout={layout}>
              <Col layout={layout}>
                <Logo height={{value: 50, unit: "px"}} variant="default" />
                <Title>{translations["UI"]["_wizard-title-events"]}</Title>
                <Text>{translations["UI"]["_wizard-text-events"]}</Text>
              </Col>
              <Col layout={layout}>
                <Figure>
                  <Proportional ratio={1}>
                    <Circle>
                      <Image width={{"value": 100, "unit": "%"}} src={theme.idlepage.image["events"]} alt="Idle"/>
                    </Circle>
                  </Proportional>
                </Figure>
              </Col>
            </Row>
          </Page>
          <Page>
            <Row layout={layout}>
              <Col layout={layout}>
                <Logo height={{value: 50, unit: "px"}} variant="default" />
                <Title>{translations["UI"]["_wizard-title-suggestions"]}</Title>
                <Text>{translations["UI"]["_wizard-text-suggestions"]}</Text>
              </Col>
              <Col layout={layout}>
                <Figure>
                  <Proportional ratio={1}>
                    <Circle>
                      <Image width={{"value": 100, "unit": "%"}} src={imageSuggestions} alt="Idle"/>
                    </Circle>
                  </Proportional>
                </Figure>
              </Col>
            </Row>
          </Page>
        </Mover>
      </Container>
      <Dots>
        <Dot isActive={offset === 0} onClick={() => setOffset(0)} />
        <Dot isActive={offset === 1} onClick={() => setOffset(1)} />
        <Dot isActive={offset === 2} onClick={() => setOffset(2)} />
        <Dot isActive={offset === 3} onClick={() => setOffset(3)} />
        <Dot isActive={offset === 4} onClick={() => setOffset(4)} />
      </Dots>
    </Wrapper>
  )
}
export default withTheme(Wizard)
