import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  border-bottom: ${props => props.borderSize+"px "+props.borderStyle+" "+props.borderColor};
  background-color: ${props => props.isActive ? props.backgroundActive : props.background};

  ${props => !props.isActive ? `
    &:hover{
      background-color: ${props => props.backgroundHover};
    }
  ` : ""};
`;

export const WrapperTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const WrapperBottom = styled.div`
  width: 100%;
  text-align: right;

  div {
    display:inline-block;
    margin-left: 10px;
  }
`;

export const UpperText = styled.div`
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};

  & em{
    font-style: normal;
    border-bottom: 2px solid ${props => props.textColor};
  }
`;

export const TitleText = styled.div`
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};
  padding: 3px 0px;

  & em{
    font-style: normal;
    border-bottom: 2px solid ${props => props.textColor};
  }
`;

export const LowerText = styled.div`
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};

  & em{
    font-style: normal;
    border-bottom: 2px solid ${props => props.textColor};
  }
`;

export const Left = styled.div`
  flex: 1;
`;

export const Right = styled.div`
`;

export const Tag = styled.div`
  display: inline-block;
  margin-left: 10px;
  background: #000;
  border-radius: 4px;
  padding: 3px 4px 4px 4px;
  font-size: 10px;
  text-transform: uppercase;
  color: #606060;
  background: #ebdfe7;

  &.bordered {
    background: transparent;
    border: 2px solid #707070;
  }
`;
