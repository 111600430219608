import React, {useState} from 'react';
import PropTypes from 'prop-types'
// import styled from 'styled-components';
import { useTheme } from './../../themes';
import {default as ReactSwitch} from "react-switch";

// const Wrapper  = styled.div`
// `;

const Switch = ({
  variant = "default",
  size = "default",
  display = "block",
  checked,
  disabled,
  callbackOn,
  callbackOff,
  labelOn = "On",
  labelOff = "Off",
  colorBackgroundOn = "lime",
  colorBackgroundOff = "gray",
  colorTextOn = "white",
  colorTextOff = "white"
}) => {
  const theme = useTheme();
  const [isChecked, setCheck] = useState(checked);

  const handleChange = (isChecked)  => {
    setCheck(isChecked);
    if(isChecked){
      callbackOn();
    }else{
      callbackOff();
    }
  };

  return (
    <ReactSwitch
      checked={isChecked}
      disabled={disabled === true}
      onChange={(isChecked) => handleChange(isChecked)}
      offColor={colorBackgroundOff}
      onColor={colorBackgroundOn}
      uncheckedIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 14,
            color: colorTextOff,
            paddingRight: 3,
            textTransform: "uppercase"
          }}
        >
          {labelOff}
        </div>
      }
      checkedIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 14,
            color: colorTextOn,
            paddingRight: 3,
            textTransform: "uppercase"
          }}
        >
          {labelOn}
        </div>
      }
    />
  )
}

Switch.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  display: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  callbackOn: PropTypes.func,
  callbackOff: PropTypes.func
}

export default React.memo(Switch)
