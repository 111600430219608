import React, {Component} from 'react';
import SubareasTabs from './SubareasTabs';
import Search from './Search/Search';
import ResultsList from './ResultsList/ResultsList';
import AnalyticsService from '../../../services/AnalyticsService';

import './Sidebar.scss';
import ResultsType from "./ResultsType/ResultsType";

class Sidebar extends Component {

  state = {
    search: ''
  };

  searchTimeout = null;

  constructor(props) {
    super(props);
    this.props = props;

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    const {data} = this.props;

    if (prevProps.data && prevProps.data.timestamp && prevProps.data.timestamp !== data.timestamp) {
      this.resetSearch();
    }

    if (prevState.search !== this.state.search) {
      this.props.onLoadMore(this.state.search, true);
    }

    if (prevProps.area !== this.props.area ) {
      this.props.onLoadMore('', true, true);
      this.resetSearch();
    }

  }

  resetSearch() {
    this.setState({
      search: ''
    })
  }

  handleSearchChange(event) {

    event.persist();
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => AnalyticsService.sendEventSearch(this.props.area, event.target.value), 500);
    this.setState({
      search: event.target.value
    })

  }

  handleLoadMore() {

    this.props.onLoadMore(this.state.search);

  }

  render() {

    const { data, translations, config, language, area, subarea, activeFilters, onToggleFilters, onSetResultsType, resultsType, detailBaseUrl, onResultItemClick } = this.props;
    const { tenant, areas } = config;
    const placeholder = translations ? translations['UI'][`_search-${area}`] : area;
    const noResultsLabel = translations ? (translations["UI"]["_no-results"] ? translations["UI"]["_no-results"] : undefined) : '_no-results';
    const noMoreResultsLabel = translations ? (translations["UI"]["_no-more-results"] ? translations["UI"]["_no-more-results"] : undefined) : '_no-more-results';

    const areaConfig = config.areas[area];

    // const { loading } = data;

    return (
      <div id='sidebar' className={"isDesktop"}>
        <div className="header">

          <Search
            value={this.state.search}
            placeholder={`${placeholder}`}
            onChange={this.handleSearchChange}
            onToggleFilters={onToggleFilters}
            isFilterActive={activeFilters}
            filters={data ? data.filters : {}}
          />
          <ResultsType onClick={onSetResultsType} active={resultsType} />
          <SubareasTabs
            subareas={areas[area].subareas}
            tenant={tenant}
            language={language}
            area={area}
            subarea={subarea}
          />
        </div>
        {(data) && (
          <>
            <ResultsList
              detailBaseUrl={detailBaseUrl}
              areaConfig={areaConfig}
              enableLogin={config.enable_login}
              className={'content'}
              area={area}
              onLoadMore={this.handleLoadMore}
              language={language}
              enableFavorite={config.enable_favorites}
              noResultsLabel={noResultsLabel}
              noMoreResultsLabel={noMoreResultsLabel}
              resultsType={resultsType}
              {...data}
              onItemClick={onResultItemClick}
            />
          </>
        )}
      </div>
    );
  }
}

export default Sidebar;
