import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'

import Authorization from './../../components/User/Authorization'
import UserPanel from './../../components/User/UserPanel'
// import Home from './../../components/Home/Home'
import List from "./../../components/List/List"
import Page from "./../../components/Page/Page"

import {MobileContentWrapper, Mover} from "./styled";
import {useSelector} from "react-redux";
import {getConfig} from "../../store/settings/selectors";
// import Meeting from "./Desktop";
import BCards from "../BCards/BCards";
import BCardsSidebar from "../BCards/Sidebar";
import Memo from "../Memo/Memo";
import MyArea from "../MyArea/MyArea";
import HelpDesk from "../Helpdesk/Helpdesk";
import Home from "../Home/Home";
import MyBCard from "../MyArea/MyBCard";
import Terms from "../Terms/Terms";

function Redirector({ match }) {

  const config = useSelector(getConfig);
  const { default_language, languages } = config;

  const toLanguage = !match || (match && languages.indexOf(match.params.lang) === -1) ? default_language : match.params.lang;

  return (
    <Redirect to={`/${toLanguage}`} />
  );
}

function Mobile({ theme, status, config, analyticsEnabled, compactM }) {

  const listRoutes = Object.keys(config.areas).filter(areaKey => config.areas[areaKey].enable).map(areaKey => {

    const activeSubareas = Object.keys(config.areas[areaKey].subareas).filter(subareaKey => config.areas[areaKey].subareas[subareaKey].enable);
    let url = `${areaKey}`;
    if (activeSubareas.length > 1) {
      url = `${areaKey}/:subarea`
    }
    return {key: areaKey, route: `/:lang/${url}`};

  });

  const detailRoutes = Object.keys(config.areas).filter(areaKey => config.areas[areaKey].enable).map(areaKey => {

    const activeSubareas = Object.keys(config.areas[areaKey].subareas).filter(subareaKey => config.areas[areaKey].subareas[subareaKey].enable);
    let url = `${areaKey}/:slug/:text?`;
    if (activeSubareas.length > 1) {
      url = `${areaKey}/:subarea/:slug/:text?`
    }
    return {key: areaKey, route: `/:lang/${url}`};
  });

  const withBar = Object.keys(config.areas).filter(key => config.areas[key].enable).length;

  return (
    <Mover isMobile={true} isOpen={status.isFilterOpen} filterWidth={theme.filters.width} listWidth={theme.list.width}>
      <MobileContentWrapper withBar={withBar}>
        <Switch>
          <Route exact path='/authorization' render={(route) => <Authorization/>}/>
          <Route exact path='/' component={Redirector}/>
          {/*<Route exact path='/:lang' render={(route) => <Home analytics={analyticsEnabled} />}/>*/}
          {/*<Route exact path='/:lang/meeting' render={() => <Meeting />}/>*/}


          <Route exact path='/:lang' render={() => <Home /> }/>
          <Route exact path='/:lang/homepage/:id' render={() => <Home /> }/>
          <Route exact path='/:lang/inspiration/:id?' render={() => <Home /> }/>
          <Route exact path='/:lang/markettrends/:id?' render={() => <Home /> }/>
          <Route exact path='/:lang/socialwall/:id?' render={() => <Home /> }/>
          <Route exact path='/:lang/winepeople/:id?' render={() => <Home /> }/>
          <Route exact path='/:lang/vinitalyworld/:id?' render={() => <Home /> }/>

          <Route exact path='/:lang/memo' render={() => <Memo />} />
          <Route exact path='/:lang/myarea' render={() => <MyArea />} />
          <Route exact path='/:lang/bcards' render={() => (
            <BCardsSidebar />
          )} />
          <Route exact path='/:lang/bcards/:id' render={() => (
            <BCards />
          )} />
          <Route exact path='/:lang/helpdesk' render={() => (
            <HelpDesk />
          )} />
          <Route exact path='/:lang/mybcard' render={() => (
            <MyBCard />
          )} />
          <Route exact path='/:lang/terms' render={() => (
            <Terms />
          )} />

          {detailRoutes.map(item => <Route exact key={`list-${item.key}`} path={`${item.route}`} children={<Page showListButton={true} analytics={true} area={item.key} />}/> )}
          {listRoutes.map(item => <Route key={`list-${item.key}`} path={item.route} children={<List analytics={true} detailBaseUrl={item.route} area={item.key} />}/> )}
        </Switch>
      </MobileContentWrapper>
      <Switch>
        <Route exact path='/authorization' render={(route) => <></>}/>
        <Route exact path='*' children={<UserPanel analytics={analyticsEnabled} />}/>
      </Switch>
    </Mover>
  );
}

export default React.memo(Mobile)
