import React, {useEffect, useState} from 'react';
import {Link, useParams, withRouter} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getLayout} from "./../../../store/settings/selectors";
import {getLocale, getTranslations} from "./../../../store/localization/selectors";
import {toggleFilters} from "./../../../store/settings/actions";
import {useTheme} from "./../../../themes";

import {LAYOUT_MD, LAYOUT_SM, LAYOUT_XS} from './../../../settings';

import NewsService from './../../../services/NewsService';

import Logo from './../../../components/App/Logo';
import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
import Post from './../../../components/App/Post';
import HomeProduct from './HomeProduct';
import Sidebar from './Sidebar';
import MenuItem from "./../../../components/App/MenuItem";
import Icon from "./../../../components/App/Icon";
import FeaturedList from "./FeaturedList";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;

  height: calc(100vh - 50px);
  height: calc(calc(var(--vh, 1vh) * 100) - 50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 100%; */
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;

  ${props => props.autoHeight ? `

    & > * {
      height: 100%;
      display: flex;
    }
  ` : `

  `};
`;

const Title = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #7B3572;
`;

const Notification = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  color: #A3A3A3;
  margin-top:20px;
`;

const Item = styled.div`
  border-radius: 12px;
  box-shadow: 0px 3px 5px #5858580F;
  overflow: hidden;
  margin-bottom: 10px;
  display: inline-block;

`;


function Home({history, featured, singleId}){
  const theme = useTheme();

  const dispatch = useDispatch();
  dispatch(toggleFilters(false));

  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);

  const [isLoading, setLoading] = useState(false);
  const [list, setList] = useState(null);
  const [single, setSingle] = useState(null);
  const [listDx, setListDx] = useState(null);
  const { featuredList, products } = featured;

  async function loadData() {

    await setLoading(true);

    try {
      const news = await NewsService.getList();
      if (singleId && !single) {
        setSingle(news['Home Page'].filter(item => item["slug_"+lang] === singleId)[0]);
        setList(null);
      } else if (!singleId && !list) {
        setSingle(null);
        setList(news['Home Page']);
      }
      setListDx(news['Frame DX']);

    } catch (e) {}

  }

  useEffect(() => {
    const unlisten = history.listen(() => {
      document.getElementById("maincnt").scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  if(!isLoading){
    loadData();
  }

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  const blockLang = lang === "it" ? lang : "en";

  return (
    <Wrapper>
      { layout !== LAYOUT_XS && layout !== LAYOUT_SM ?
        <Spacer size={{value: 34, unit: "px"}} />
      : <Sidebar selected={"homepage"} /> }
      <MainContainer id="maincnt">
        <Container>
          <MacroRow layout={layout}>
            <MacroCol size="66" layout={layout}>
              <center>
                { layout === LAYOUT_XS || layout === LAYOUT_SM ?
                  <Spacer size={{value: 34, unit: "px"}} />
                : null }
                <Logo height={{value: 56, unit: "px"}} variant="plus" />
                <Spacer size={{value: 27, unit: "px"}} />
              </center>
              {list ? list.map((item, i) =>
                <React.Fragment key={i}>
                  <Post
                    tags={["Homepage"]}
                    title={item[`titolo_${blockLang}`]}
                    abstract={ReactHtmlParser(item[`abstract_${blockLang}`])}
                    content={null}
                    ctaText={translations["UI"]["Show more"]}
                    ctaLinkInternal={"/"+lang+"/homepage/"+item["slug_"+lang]}
                    timestamp={item.data_pubblicazione}
                    image={item.immagine}
                  />
                  <Spacer size={{value: 34, unit: "px"}} />
                  {featuredList && featuredList[i] ?
                    <FeaturedList block={featuredList[i]} translations={translations} products={products} />
                  : null }
                </React.Fragment>
              ) : null }

              {single ?
                <>
                  <Link to={"/"+lang+"/"}>
                    <Item>
                      <MenuItem
                        leftIcon={<Icon color={"#585858"} name={theme.icons.set.menuitemPrev} />}
                        variant={theme.homepage.menuitemVariant}
                        size={theme.homepage.menuitemSize}
                      >{translations["UI"]["Back"]}</MenuItem>
                    </Item>
                  </Link>
                  <Post
                    tags={["Homepage"]}
                    title={single[`titolo_${blockLang}`]}
                    abstract={ReactHtmlParser(single[`abstract_${blockLang}`]) + single[`testo_${blockLang}`] ? ReactHtmlParser(single[`testo_${blockLang}`]) : null}
                    content={null}
                    ctaText={single[`cta_${blockLang}`] ? ReactHtmlParser(single[`cta_${blockLang}`]) : null}
                    ctaLink={single[`link_${blockLang}`] ? ReactHtmlParser(single[`link_${blockLang}`]) : null}
                    timestamp={single.data_pubblicazione}
                    image={single.immagine}
                  />
                  <Spacer size={{value: 34, unit: "px"}} />
                </>
              : null }

            </MacroCol>
            <MacroCol size="34" layout={layout}>
              <Spacer size={{value: 95, unit: "px"}} />
              {/*<FindUs />
              <Spacer size={{value: 34, unit: "px"}} />*/}
              {listDx ? listDx.map((item, i) =>
                <React.Fragment key={i}>
                  <Post
                    isHighlighted={true}
                    title={item["titolo_"+(lang === "it" ? lang : "en")]}
                    abstract={ReactHtmlParser(item[`abstract_${blockLang}`])}
                    content={item[`testo_${blockLang}`] ? ReactHtmlParser(item[`testo_${blockLang}`]) : null}
                    ctaText={item[`cta_${blockLang}`] ? ReactHtmlParser(item[`cta_${blockLang}`]) : null}
                    ctaLink={item[`link_${blockLang}`] ? ReactHtmlParser(item[`link_${blockLang}`]) : null}
                    image={item.immagine}
                  />
                  <Spacer size={{value: 34, unit: "px"}} />
                </React.Fragment>
              ) : null }
            </MacroCol>
          </MacroRow>
          <Notification>
            {translations["UI"]["_homepage-language-info"]}
            <Spacer size={{value: 34, unit: "px"}} />
          </Notification>
        </Container>
      </MainContainer>
    </Wrapper>

  );

}
export default withRouter(Home);
