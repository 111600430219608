import { all, fork } from 'redux-saga/effects'

import authSaga from './auth/sagas'
import settingsSaga from './settings/sagas'
import localizationSaga from './localization/sagas'
import companiesSaga from './companies/sagas'
import productsSaga from './products/sagas'
import casehistoriesSaga from './casehistories/sagas'
import eventsSaga from './events/sagas'
import chatSaga from './chat/sagas'
import bcardSaga from './bcard/sagas'

export default function* root() {
  yield all([
    fork(authSaga),
    fork(settingsSaga),
    fork(localizationSaga),
    fork(companiesSaga),
    fork(productsSaga),
    fork(casehistoriesSaga),
    fork(eventsSaga),
    fork(chatSaga),
    fork(bcardSaga),
  ])
}
