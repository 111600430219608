import React from 'react';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getLocale, getTranslations} from "../../../store/localization/selectors";
import {useTheme} from "../../../themes";

import Post from './../../../components/App/Post';
import Button from "./../../../components/App/Button";

const Wrapper = styled.div`
  padding-bottom: 34px;
`;

export default function HomeProduct({item = null}){

  const theme = useTheme();
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  const product = item;

  return (
    <Wrapper>
      {product ?
        <Post
          isRecommended={false}
          tags={[product.options.exhibitor.name]}
          title={product.options.name[lang]}
          abstract={
            <>
              {product.options.category ? ReactHtmlParser(product.options.category[lang]) : null}
              <br/><br/>
              <center>
                <Link to={"/"+lang+"/products/"+(product.type === "wine" ? "wines" : "products")+"/"+product.id}>
                  <Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Discover"]}</Button>
                </Link>
              </center>
            </>
          }
          image={product.options.imageId ? product.options.imageId.src : theme.placeholders.product}
          imageHeight={{"value": 150, "unit": "px"}}
          imageFit={"contain"}
          imagePadding={true}
        />
      : null}
    </Wrapper>
  );

}
