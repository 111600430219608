import React from 'react';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import styled from 'styled-components';
import { getTranslations } from "./../../../store/localization/selectors";
import { getConfig, getLayout } from './../../../store/settings/selectors';
import { useParams } from 'react-router-dom';
// import { useTheme } from './../../../themes';
// import { getTranslations } from "../../store/localization/selectors";

import Image from './../../../components/App/Image';
import Button from './../../../components/App/Button';
import Spacer from './../../../components/App/Spacer';

import placeholder from './../assets/placeholder-company.png';

import {LAYOUT_XS, LAYOUT_SM} from './../../../settings'


const Wrapper = styled(Link)`
  display: block;
  border-radius: 10px;
  border: 1px solid #c9c9c9;
  -webkit-box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.2);
  box-shadow: 1px 3px 3px 0px rgba(0,0,0,0.2);
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  max-width: 700px;
  margin: 0 auto;
`;
const Photo = styled.div`
  border-bottom: 1px solid #c9c9c9;
  position: relative;
  width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "50%" : "100%"};
  height: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "auto" : "200px"};
`;
const Content = styled.div`
  text-align: left;
  margin: 0px;
  position: relative;
  width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "50%" : "100%"};
  display: inline-block;
  color: #A3A3A3;
`;
const ContentPush = styled.div`
  display: block;
  padding-top: 99.99%;
`;
const ContentCnt = styled.div`
  position: absolute;
  margin: 0;
  padding: 0px 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;


const Description = styled.div`
  padding: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  text-align:center;
`;

const Name = styled.div`
  color: #7B3572;
  font-size: 21px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

function VirCompanyThumb(props) {

  // const theme = useTheme();
  const config = useSelector(getConfig);
  const translations = useSelector(getTranslations);
  const layout = useSelector(getLayout);
  const { data } = props;
  let { lang } = useParams()

  let subareaSlug = null;
  if(data){
    let subareas = config.areas["products"].subareas;
    Object.keys(subareas).forEach(function(index){
      if(subareas[index].key === data.type){
        subareaSlug = index;
      }
    });
  }

  return data ? (
    <Wrapper layout={layout} to={"/"+lang+"/companies/"+data.slug}>
      <Photo layout={layout}>
        <Image fit={data.cover ? "cover" : "contain"} width={{value: 100, unit: "%"}} height={{value: 100, unit: "%"}} src={data.cover ? data.cover : placeholder} alt={data.name} />
      </Photo>
      <Content layout={layout}>
        <ContentPush />
        <ContentCnt>
           <Description>
             {data.logo ?
               <>
                 <Image width={{value: 80, unit: "%"}} height={{value: 100, unit: "px"}} src={data.logo} alt={data.name} fit="contain" />
                 <Spacer size={{value: 20, unit: "px"}} />
               </>
             : <Spacer size={{value: 60, unit: "px"}} /> }
             <Name>{data.name}</Name>
             <Spacer size={{value: 20, unit: "px"}} />
             <Button display="inline-block">{translations["sheet-product"]["_more-company-info"]}</Button>
            {/*}<Category>{data.category.join(", ")}</Category>*/}
          </Description>
       </ContentCnt>
     </Content>
    </Wrapper>
  ) : <></>;
}

export default React.memo(VirCompanyThumb)
