import { createRoutine } from 'redux-saga-routines';

export const CONFIG_INIT = "Config.Init";
export const CONFIG_SETLAYOUT = "Config.SetLayout";
export const CONFIG_SETSTATUS = "Config.SetStatus";
export const SET_READY = "App.Ready";
export const SET_AREA = "App.SetArea";
export const SET_SUBAREA = "App.SetSubarea";
export const SET_RESULTS_TYPE = "App.SetResultsType";
export const TOGGLE_FILTERS = "App.ToggleFilters";
export const TOGGLE_SUBAREAS = "App.ToggleSubareas";
export const SET_FILTERS_LEVELS = "App.SetFiltersLevel";
export const ADD_FILTERS_LEVEL = "App.AddFiltersLevel";
export const TOGGLE_MODAL = "App.ToggleModal";
export const SHOW_PLANS_MODAL = "App.ShowPlansModal";
export const SHOW_FULLMODAL = "App.ShowFullModal";
export const HIDE_FULLMODAL = "App.HideFullModal";

export const init = createRoutine(CONFIG_INIT);
export const setLayout = createRoutine(CONFIG_SETLAYOUT);
export const setStatus = createRoutine(CONFIG_SETSTATUS);
export const setReady = createRoutine(SET_READY);
export const setArea = createRoutine(SET_AREA);
export const setSubarea = createRoutine(SET_SUBAREA);
export const toggleFilters = createRoutine(TOGGLE_FILTERS);
export const setResultsType = createRoutine(SET_RESULTS_TYPE);
export const toggleSubareas = createRoutine(TOGGLE_SUBAREAS);
export const setLevels = createRoutine(SET_FILTERS_LEVELS);
export const addLevel = createRoutine(ADD_FILTERS_LEVEL);
export const toggleModal = createRoutine(TOGGLE_MODAL);
export const showPlansModal = createRoutine(SHOW_PLANS_MODAL);
export const showFullModal = createRoutine(SHOW_FULLMODAL);
export const hideFullModal = createRoutine(HIDE_FULLMODAL);
