import React from 'react';
import { useSelector} from "react-redux";
import { getConfig } from "./../../store/settings/selectors";

import VirHomepage from "./../../tenants/VIR/Home/Home";
import MdmHomepage from "./../../tenants/MDM/Home/Home";
import AgrHomepage from "./../../tenants/AGR/Home/Home";
import FicHomepage from "./../../tenants/FIC/Home/Home";
import SamHomepage from "./../../tenants/SAM/Home/Home";
import AsfHomepage from "./../../tenants/ASF/Home/Home";

import Analytics from "../Analytics/Analytics";

const MainComponent = ({ tenant }) => {
  switch (tenant) {
    case "VIR":
      return <VirHomepage />;
    case "MDM":
      return <MdmHomepage />;
    case "AGR":
      return <AgrHomepage />;
    case "FIC":
      return <FicHomepage />;
    case "SAM":
      return <SamHomepage />;
    case "ASF":
      return <AsfHomepage />;
    default:
      return <div>Homepage default</div>;
  }
}

export default function Home(){
  const config = useSelector(getConfig);
  return (
    <>
      <Analytics name="home" />
      <MainComponent tenant={config.tenant} />
    </>
  )

}
