import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { getTranslations, getLocale } from "./../../../store/localization/selectors";
import { getUser } from "./../../../store/auth/selectors";
import { getLayout } from "./../../../store/settings/selectors";
import {useTheme} from "./../../../themes";

import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../../settings';

import MenuItem from "./../../../components/App/MenuItem";
import Icon from "./../../../components/App/Icon";
import Spacer from './../../../components/App/Spacer';


const Wrapper  = styled.div`
  position: relative;

  ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? `
    padding-left: 50%;
    padding-top: 20px;
  ` : `
    background-color: #fff;
    width:100%;
    padding: 5px 0px;
  `};

`;
const Container = styled.div`
  ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? `
    width: calc(100% - 30px);
    margin: 0px 15px;
  ` : `

  `};
`;
const Menu = styled.div`
  ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? `
  ` : `
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    padding-top: 5px;
  ` + (props.isOpen ? `
      height: auto;
  ` : `
      height: 0px;
  `)};
`;
const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background-color: ${props => props.color};
`;
const Void = styled.div`
  width: 8px;
  height: 8px;
`;

const Toggler = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 5px 14px 0px;
  font-size: 12px;
  font-weight: 600;
  color: #585858;
`;

const Item = styled.div`
  border-radius: 12px;
  box-shadow: 0px 3px 5px #5858580F;
  overflow: hidden;
  margin-bottom: 10px;

`;


export default function Sidebar({selected}) {
  const theme = useTheme();
  const lang = useSelector(getLocale);
  const translations = useSelector(getTranslations);
  const user = useSelector(getUser);
  const layout = useSelector(getLayout);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper layout={layout}>
      { layout !== LAYOUT_XS && layout !== LAYOUT_SM ?
        <Spacer size={{value: 105, unit: "px"}} />
      : <Toggler onClick={() => setIsOpen(!isOpen) }><Icon color={"#585858"} name={theme.icons.set.menu} size={{value: 20, unit: "px"}} /> &nbsp; {translations["UI"]["_home-menu"]}</Toggler> }
      <Container layout={layout}>
        <Menu layout={layout} isOpen={isOpen}>
          <Link to={"/"+lang}>
            <Item>
              <MenuItem
                onClick={() => {}}
                isActive={selected === "homepage"}
                leftIcon={selected === "homepage" ? <Dot /> : <Void />}
                rightIcon={selected === "homepage" ? <Icon color={"#585858"} name={theme.icons.set.menuitemNext} /> : <></>}
                variant={theme.homepage.menuitemVariant}
                size={theme.homepage.menuitemSize}
              >{translations["UI"]["_homepage-section-homepage"]}</MenuItem>
            </Item>
          </Link>
          <a target="_blank" href="http://liveshop.vinitaly.com/">
            <Item>
              <MenuItem
                onClick={() => {}}
                isActive={false}
                leftIcon={false ? <Dot /> : <Void />}
                rightIcon={false ? <Icon color={"#585858"} name={theme.icons.set.menuitemNext} /> : <></>}
                variant={theme.homepage.menuitemVariant}
                size={theme.homepage.menuitemSize}
              >{translations["UI"]["_homepage-section-upcoming"]}</MenuItem>
            </Item>
          </a>
          <a target="_blank" href="https://liveshop.vinitaly.com/categorie/eventi-on-demand/">
            <Item>
              <MenuItem
                onClick={() => {}}
                isActive={false}
                leftIcon={false ? <Dot /> : <Void />}
                rightIcon={false ? <Icon color={"#585858"} name={theme.icons.set.menuitemNext} /> : <></>}
                variant={theme.homepage.menuitemVariant}
                size={theme.homepage.menuitemSize}
              >{translations["UI"]["_homepage-section-videochannel"]}</MenuItem>
            </Item>
          </a>
          <Link to={"/"+lang+"/inspiration"}>
            <Item>
              <MenuItem
                onClick={() => {}}
                isActive={selected === "inspiration"}
                leftIcon={selected === "inspiration" ? <Dot /> : <Void />}
                rightIcon={selected === "inspiration" ? <Icon color={"#585858"} name={theme.icons.set.menuitemNext} /> : <></>}
                variant={theme.homepage.menuitemVariant}
                size={theme.homepage.menuitemSize}
              >{translations["UI"]["_homepage-section-inspiration"]}</MenuItem>
            </Item>
          </Link>
          {/*<Link to={"/"+lang+"/socialwall"}>
            <MenuItem
              onClick={() => {}}
              isActive={selected === "socialwall"}
              leftIcon={selected === "socialwall" ? <Dot /> : <Void />}
              variant={theme.homepage.menuitemVariant}
              size={theme.homepage.menuitemSize}
            >{translations["UI"]["_homepage-section-socialwall"]}</MenuItem>
          </Link>*/}
          <Link to={"/"+lang+"/winepeople"}>
            <Item>
              <MenuItem
                onClick={() => {}}
                isActive={selected === "winepeople"}
                leftIcon={selected === "winepeople" ? <Dot /> : <Void />}
                rightIcon={selected === "winepeople" ? <Icon color={"#585858"} name={theme.icons.set.menuitemNext} /> : <></>}
                variant={theme.homepage.menuitemVariant}
                size={theme.homepage.menuitemSize}
              >{translations["UI"]["_homepage-section-winepeople"]}</MenuItem>
            </Item>
          </Link>
          <Link to={"/"+lang+"/markettrends"}>
            <Item>
              <MenuItem
                onClick={() => {}}
                isActive={selected === "markettrends"}
                leftIcon={selected === "markettrends" ? <Dot /> : <Void />}
                rightIcon={selected === "markettrends" ? <Icon color={"#585858"} name={theme.icons.set.menuitemNext} /> : <></>}
                variant={theme.homepage.menuitemVariant}
                size={theme.homepage.menuitemSize}
              >{translations["UI"]["_homepage-section-markettrends"]}</MenuItem>
            </Item>
          </Link>
          <Link to={"/"+lang+"/vinitalyworld"}>
            <Item>
              <MenuItem
                onClick={() => {}}
                isActive={selected === "vinitalyworld"}
                leftIcon={selected === "vinitalyworld" ? <Dot /> : <Void />}
                rightIcon={selected === "vinitalyworld" ? <Icon color={"#585858"} name={theme.icons.set.menuitemNext} /> : <></>}
                variant={theme.homepage.menuitemVariant}
                size={theme.homepage.menuitemSize}
              >{translations["UI"]["_homepage-section-vinitalyworld"]}</MenuItem>
            </Item>
          </Link>
          <a target="_blank" href={lang === "it" ? "https://liveshop.vinitaly.com/categorie/eventi-in-fiera/wine2wine/" : "https://liveshop.vinitaly.com/en/categories/event-in-vinitaly-fair/wine2wine/"}>
            <Item>
              <MenuItem
                onClick={() => {}}
                isActive={false}
                leftIcon={false ? <Dot /> : <Void />}
                rightIcon={false ? <Icon color={"#585858"} name={theme.icons.set.menuitemNext} /> : <></>}
                variant={theme.homepage.menuitemVariant}
                size={theme.homepage.menuitemSize}
              >{translations["UI"]["_homepage-section-wine2wine"]}</MenuItem>
            </Item>
          </a>
        </Menu>
      </Container>
    </Wrapper>
  )
};
